import * as React from 'react';

import { DialogProps } from '@mui/material';
import { useWidth } from '../../hooks';
import { isExtraSmall } from '../../device';
import { Dialog_Settings } from 'src/themes/Styled';

const Dialog: React.FC<DialogProps> = (props) => {
  const { children, ...other } = props;
  const width = useWidth();

  return (
    <Dialog_Settings fullScreen={isExtraSmall(width)} {...other}>
      {children}
    </Dialog_Settings>
  );
};

export default Dialog;
