import * as React from 'react';

import 'typeface-roboto';
// import './App.css';

import { UserPersonTheme } from 'attentive-connect-store/dist/models/UserPerson';
import { FirebaseRuntime } from 'attentive-connect-store/dist/services/Firebase';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AppAuth from './AppAuth';
import deployment from './Deployment';
import { getLogger } from './logger';
import * as store from './redux';
import PollingService from './services/PollingService';
import AudioNotificationService from './services/AudioNotificationService';
import env from 'attentive-connect-store/dist/config/Environment';
import AlertNotificationService from './services/AlertNotificationService';

const logger = getLogger('App');

interface PropsFromRedux {
  // locale: localeStore.LocaleState,
  auth: store.auth.AuthState;
  themeFontSize?: number;
  themeName?: UserPersonTheme;
}

interface AppState {
  offline: boolean;
}

type AppProps = PropsFromRedux & WrappedComponentProps;

class App extends React.Component<AppProps, AppState> {
  readonly state: AppState = {
    offline: false,
  };

  async componentDidMount() {
    // push notifications - moved to AppAuth
    // await push.setAppId(pushAppId());
  }

  componentDidUpdate() {
    // place holder...
  }

  checkConnection = () =>
    FirebaseRuntime.instance
      .checkFirestoreConnection()
      .then(() => {
        if (this.state.offline) {
          this.setState({ offline: false });
          logger.debug('offline: false');
        }
      })
      .catch((e) => {
        if (!this.state.offline) {
          this.setState({ offline: true });
          logger.debug('offline: true', e);
        }
      });

  // need this in order to support deployment in a sub-directory
  // @see Router.basename
  location = () => deployment.publicUrl.pathname;

  render() {
    logger.debug('render');
    // const views = <RouterProvider router={env.isHybrid() ? hashRouter : browserRouter} />;

    return (
      <React.Fragment>
        <AppAuth offline={this.state.offline} />
        <AlertNotificationService />
        <PollingService pollInterval={25000} polling="biobeat-vitals" />
        <PollingService pollInterval={1001} polling="aisleep-vitals" />
        <PollingService pollInterval={5001} polling="sensingWave-vitals" />
        {env().isEmulator() ? null : (
          <PollingService pollInterval={61000} polling="bed-occupancy" />
        )}
        <PollingService pollInterval={7000} polling="current-version" />
        <AudioNotificationService />
      </React.Fragment>
    );
  }
}

// map redux ApplicationState to component properties
const mapStateToProps = ({ auth, layout }: store.ApplicationState) => ({
  auth,
  themeFontSize: layout.fontSize,
  themeName: layout.themeName,
});

// map redux dispatch actions to component properties
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setLanguageFromBrowser: () => dispatch(store.locale.setLanguageFromBrowser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(App));
