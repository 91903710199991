// Deemed obsolete by Jami 9/2024 with AC-1247
import * as React from 'react';
import * as Icons from '../../icons';
import { SxProps, Theme, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { UserPersonTheme } from 'attentive-connect-store/dist/models';
import classNames from 'classnames';
import { useLocale } from '../../hooks';

interface Props {
  className?: string;
  sx?: SxProps<Theme>;
  // callback when a selection is made
  onChange: (theme: UserPersonTheme) => void;
  // the current selection
  theme: UserPersonTheme;
}

const ThemeSettings: React.FC<Props> = (props: Props) => {
  const localized = useLocale();

  return (
    <ToggleButtonGroup
      className={classNames(props.className)}
      sx={{ ...props.sx }}
      value={props.theme}
      exclusive
      onChange={(event: unknown, theme: UserPersonTheme) => props.onChange(theme)}
      aria-label="font-size-settings"
    >
      <ToggleButton value={UserPersonTheme.light} aria-label="light theme">
        <Icons.LightMode fontSize="medium" sx={{ marginLeft: 1, marginRight: 1 }} />
        <Typography sx={{ paddingRight: 1 }} fontSize="small">
          {localized.theme.light()}
        </Typography>
      </ToggleButton>
      <ToggleButton value={UserPersonTheme.dark} aria-label="dark theme">
        <Icons.DarkMode fontSize="medium" sx={{ marginLeft: 1, marginRight: 1 }} />
        <Typography sx={{ paddingRight: 1 }} fontSize="small">
          {localized.theme.dark()}
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ThemeSettings;
