// Deemed obsolete by Jami 9/2024 with AC-1247
import { WrappedComponentProps, injectIntl } from 'react-intl';
import strings from '../../languages';
import * as Icons from '../../icons';
import { Stack, SxProps, Theme, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

interface ComponentProps {
  className?: string;
  sx?: SxProps<Theme>;
  // callback when a selection is made
  onChange: (sortByRoom: boolean) => void;
  // the current selection
  sortByRoom: boolean;
}

type Props = ComponentProps & WrappedComponentProps;

export const DashboardLayoutSettings = injectIntl((props: Props) => {
  const localized = strings(props.intl);

  return (
    <Stack spacing={0.5}>
      <ToggleButtonGroup
        sx={{ ...props.sx }}
        value={props.sortByRoom}
        exclusive
        onChange={(event: unknown, sortByRoom: boolean) => props.onChange(sortByRoom)}
        aria-label="font-size-settings"
        className="AC-wideButtons"
      >
        <ToggleButton value={true} aria-label="by room" color="info">
          <Icons.SortByRoom />
        </ToggleButton>
        <ToggleButton value={false} aria-label="by category" color="success">
          <Icons.SortByCategory />
        </ToggleButton>
      </ToggleButtonGroup>
      {props.sortByRoom ? (
        <Typography sx={{ paddingRight: 1 }} fontSize="small">
          {localized.dashboard.sortByRoom()}
        </Typography>
      ) : (
        <Typography sx={{ paddingRight: 1 }} fontSize="small">
          {localized.dashboard.groupByStatus()}
        </Typography>
      )}
    </Stack>
  );
});
