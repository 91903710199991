import {
  AppBar,
  Box,
  Card,
  Dialog,
  Drawer,
  Paper,
  Stack,
  CSSInterpolation,
  Theme,
  alpha,
  darken,
  lighten,
  styled,
} from '@mui/material';
import { UserPersonTheme } from 'attentive-connect-store/dist/models';
import { isVendorToppan } from '../Vendor';
import * as Layout from './';

export const isIos = /iP(ad|od|hone)/i.test(window.navigator.userAgent);
export const isSafari = !!navigator.userAgent.match(/Version\/.*Safari/);

////////////////////////////////////////////////
// Guide
////////////////////////////////////////////////

// Example of how to define styles
export const StyleTemplate = {
  style1: (theme: Theme): CSSInterpolation => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    padding: theme.spacing(1),
    ...theme.typography.caption,
    textAlign: 'center',
    color: theme.palette.text.primary,
  }),
  style2: (): CSSInterpolation => ({
    backgroundColor: 'red',
    padding: '10px',
  }),
};

// Example of how to define styled elements
export const Div_StyleTemplate = styled('div')(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(2),
  color: alpha(theme.palette.warning.main, 0.66),

  '.style1': {
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    padding: theme.spacing(1),
    ...theme.typography.caption,
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  '&.style2': {
    backgroundColor: 'red',
    padding: '10px',
  },
}));

// Example for templates.
// @see TemplateFunction
// @see TemplateClass
export const TemplateStyles = {
  style1: (theme: Theme): CSSInterpolation => ({
    padding: theme.spacing(2),
  }),
};

////////////////////////////////////////////////
// Re-Used Styles
////////////////////////////////////////////////

const ToolbarDivider = '1px solid rgba(255, 255, 255, 0.25)';

export const TextStyles = {
  ellipsis: (): CSSInterpolation => ({
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
};

export const AnimationStyles = {
  spin: (): CSSInterpolation => ({
    '@keyframes spin': {
      from: { transform: 'rotate(0deg)' },
      to: { transform: 'rotate(-360deg)' },
    },
    animation: `spin 1s infinite`,
  }),
  awake: (theme: Theme): CSSInterpolation => ({
    '@keyframes awake': {
      '0%, 100%': {
        transform: 'scale(1)',
        fill: '#0288D1',
      },
      '5%': {
        transform: 'scale(1.1)',
        fill: '#FFD303',
      },
      '25%': {
        transform: 'scale(1)',
      },
    },
    animation: `awake 2.5s ${theme.transitions.easing.easeIn} 3 normal`,
  }),
  asleep: (theme: Theme): CSSInterpolation => ({
    '@keyframes asleep': {
      '0%, 60%, 100%': {
        transform: 'scale(1)',
        fill: '#0288D1',
      },
      '25%': {
        transform: 'scale(.9)',
        fill: '#014265',
      },
    },
    animation: `asleep 5s ${theme.transitions.easing.easeOut} 3 normal`,
  }),
  pulse: (theme: Theme): CSSInterpolation => ({
    '@keyframes pulse': {
      '0%, 12%, 32%, 100%': {
        backgroundColor: alpha(theme.palette.error.main, 0.18),
      },
      '6%, 18%': {
        backgroundColor: alpha(theme.palette.error.main, 0.36),
      },
    },
    animation: `pulse 5s ${theme.transitions.easing.easeInOut} 36`,
  }),
};

////////////////////////////////////////////////
// Styled Components
////////////////////////////////////////////////

export const Dialog_Settings = styled(Dialog)(({ theme }) => ({
  '.MuiDialogTitle-root': {
    position: 'relative',
  },

  '.AC-backButton': {
    color: 'inherit',
    position: 'absolute',
    top: '50%',
    left: theme.spacing(1),
    transform: 'translateY(-50%)',

    '.MuiButton-icon': {
      color: 'inherit',
    },
  },

  '.AC-settingsContent': {
    padding: theme.spacing(2),
    backgroundColor: 'rgba(0,0,0,0.06)',
  },

  '.AC-settingsListCaption': {
    margin: theme.spacing(0) + ' ' + theme.spacing(2) + ' ' + theme.spacing(1),
  },

  '.AC-settingsList': {
    padding: theme.spacing(0),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
  },

  '.AC-selectListItemAction': {
    right: theme.spacing(1),
  },
}));

export const Box_PageLayout = styled(Box)(({ theme }) => ({
  // aka AC-pageLayout
  display: 'grid',
  // Presumes only 3 children: app bar, page content and page toolbar
  gridTemplateRows: 'auto 1fr auto',
  width: '100vw',
  height: '100vh',
  overflowX: 'hidden',

  '&.AC-isMobileSafari': {
    // CSS specific to iOS devices
    // this is the only way to get the sticky footer to always display
    height: '-webkit-fill-available',
  },

  '.AC-appBar': {
    gridColumn: 1,
    gridRow: 1,
    alignSelf: 'start',
    position: 'sticky',

    zIndex: theme.zIndex.drawer + 1,
    height: Layout.constants.TOOLBAR_HEIGHT,
    maxWidth: '100%',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      height: Layout.constants.TOOLBAR_HEIGHT_XS,
      minHeight: 0,
    },

    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    '&.AC-appBarShift': {
      marginLeft: Layout.constants.DRAWER_WIDTH,
      width: `calc(100% - ${Layout.constants.DRAWER_WIDTH}px)`,
    },

    '.AC-appToolbar': {
      minHeight: 64,

      [theme.breakpoints.down('sm')]: {
        minHeight: 0,
        height: '100%',
      },
    },
  },

  '.AC-pageBar': {
    gridColumn: 1,
    gridRow: 3,
    alignSelf: 'end',

    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    minHeight: 56,
    transition: theme.transitions.create(['bottom'], {
      easing: theme.transitions.easing.easeOut,
      duration: 250,
      delay: 500,
    }),

    '@media (min-height: 729px) and (min-width: 729px)': {
      // floats on taller space
      placeSelf: 'end center',
      gridRow: '2',
      zIndex: 2,
      marginBottom: theme.spacing(3),
      width: 600,
      marginLeft: -300,
      left: '50%',
    },

    '&.AC-hideBar': {
      bottom: theme.spacing(-10),
    },

    '.AC-pageToolbar': {
      minHeight: 56,
    },
  },

  '.AC-appBar, .AC-pageBar': {
    '.AC-toolbarItem': {
      alignSelf: 'stretch',
      display: 'flex',
      alignItems: 'center',

      '.MuiButtonBase-root, .MuiFormControl-root': {
        margin: 0,
      },
    },

    '.AC-isSpacer': {
      flex: 1,
      minWidth: 0,
    },

    '.AC-withLeftDivider': {
      borderLeft: ToolbarDivider,
      [theme.breakpoints.down('sm')]: {
        borderLeft: 'none !important',
      },
    },

    '.AC-withRightDivider': {
      borderRight: ToolbarDivider,
      [theme.breakpoints.down('sm')]: {
        borderRight: 'none !important',
      },
    },

    '.AC-withPadding': {
      paddingLeft: theme.spacing(1.125),
      paddingRight: theme.spacing(1.125),
    },
  },

  '.AC-pageContent': {
    display: 'grid',
    gridColumn: 1,
    gridRow: 2,

    width: '100%',
    overflowY: 'auto',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(13),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(9),
    },

    '.AC-contentExpanded': {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
    },

    '.AC-contentLimited': {
      width: '100%',
      height: '100%',
      maxWidth: 1440 - 32,
      margin: '0 auto',
    },

    '.AC-sortByCategory .MuiAccordion-root:last-child .MuiAccordionDetails-root': {
      paddingBottom: 0,
    },
  },

  '&.AC-dashboardVariant .AC-pageContent': {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      paddingBottom: theme.spacing(8),
    },
  },
}));

export const Paper_StatusTile = styled(Paper)(({ theme }) => ({
  // aka AC-statusTile
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.paper,
  borderColor: theme.palette.background.paper,
  borderRadius: theme.spacing(0.5),
  boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.24)',

  // '.AC-compactTiles &': {
  //   '.MuiTypography-root': {
  //     fontStretch: '75%',
  //   },
  // },

  '.AC-expandTiles &': {
    '.AC-iconOn, .AC-iconOff': {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },

  '.AC-statusChip': {
    backgroundColor:
      theme.palette.mode === UserPersonTheme.light
        ? alpha(lighten(theme.palette.text.primary, 0.9), 0.9)
        : alpha(darken(theme.palette.text.primary, 0.75), 0.9),
  },

  '.AC-iconOn': {
    color: theme.palette.primary.main,
  },

  '.AC-iconOff': {
    color: theme.palette.action.disabled,
  },

  '.AC-residentDetails &': {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },

  '&.AC-tileAlert': {
    borderColor: theme.palette.error.light,
    '.AC-tileHeader, .AC-vitalsContainer': {
      backgroundColor: alpha(theme.palette.error.main, 0.18),
    },
    '.AC-statusIcon, .AC-iconOn': {
      color:
        theme.palette.mode === UserPersonTheme.light
          ? theme.palette.error.dark
          : theme.palette.error.main,
    },
    '.AC-statusChip': {
      backgroundColor:
        theme.palette.mode === UserPersonTheme.light
          ? alpha(lighten(theme.palette.error.main, 0.7), 0.9)
          : alpha(darken(theme.palette.error.main, 0.45), 0.9),
    },
  },

  '&.AC-tileWarning': {
    borderColor: theme.palette.warning.light,
    '.AC-tileHeader, .AC-vitalsContainer': {
      backgroundColor: alpha(theme.palette.hilite.main, 0.15),
    },
    '.AC-statusIcon, .AC-iconOn': {
      color:
        theme.palette.mode === UserPersonTheme.light
          ? theme.palette.warning.dark
          : theme.palette.warning.main,
    },
    '.AC-statusChip': {
      backgroundColor:
        theme.palette.mode === UserPersonTheme.light
          ? alpha(lighten(theme.palette.warning.main, 0.7), 0.9)
          : alpha(darken(theme.palette.warning.main, 0.55), 0.9),
    },
  },

  '&.AC-tileNoStatus': {
    borderColor: alpha(theme.palette.divider, 0.03),
    '.AC-tileHeader, .AC-vitalsContainer': {
      backgroundColor: alpha(theme.palette.divider, 0.08),
    },
    '.AC-statusIcon, .AC-iconOn, .AC-iconOff': {
      color: theme.palette.action.disabled,
    },
  },

  '&.AC-tileDozing': {
    borderColor:
      theme.palette.mode === UserPersonTheme.light
        ? theme.palette.deepSleep.light
        : theme.palette.deepSleep.dark,
    '.AC-tileHeader, .AC-vitalsContainer': {
      backgroundColor: alpha(theme.palette.deepSleep.main, 0.15),
    },
  },

  '&.AC-tilePaused': {
    borderColor:
      theme.palette.mode === UserPersonTheme.light
        ? theme.palette.success.light
        : theme.palette.success.dark,
    '.AC-tileHeader, .AC-vitalsContainer': {
      backgroundColor: alpha(theme.palette.success.main, 0.15),
    },
    '.AC-statusIcon, .AC-iconOn': {
      color:
        theme.palette.mode === UserPersonTheme.light
          ? theme.palette.success.dark
          : theme.palette.success.main,
    },
    '.AC-statusChip': {
      backgroundColor:
        theme.palette.mode === UserPersonTheme.light
          ? alpha(lighten(theme.palette.success.main, 0.725), 0.9)
          : alpha(darken(theme.palette.success.main, 0.5), 0.9),
    },
  },

  '.AC-tileHeader': {
    // maxWidth: '100%',
    padding: theme.spacing(3),
    gap: theme.spacing(2),

    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },

    '.AC-compactTiles &': {
      padding: theme.spacing(1),
      gap: theme.spacing(1),

      '.AC-infoGroup': {
        gap: theme.spacing(1),
      },
    },
  },

  '.AC-residentButton': {
    padding: 0,
    paddingRight: theme.spacing(1),
    borderRadius: theme.spacing(0.25),
    flex: 1,
    // width: '100%',
    // maxWidth: '100%',
    justifyContent: 'start',
    color: theme.palette.text.primary + '!important',

    '.MuiTypography-root': {
      textAlign: 'left',
    },
  },

  '.AC-statusIcon': {
    color:
      theme.palette.mode === UserPersonTheme.light
        ? theme.palette.info.main
        : theme.palette.info.light,
    justifySelf: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),

    '.AC-compactTiles &': {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  },

  '.AC-statusIconDisabled': {
    color: theme.palette.action.disabled,
  },

  '.AC-vitalsContainer': {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gap: theme.spacing(2),
    padding: theme.spacing(3),
    paddingTop: 0,

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      paddingTop: 0,
    },

    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },

    '.AC-compactTiles &': {
      gap: 2,
      padding: 2,
      paddingTop: 0,
    },
  },

  '.AC-priorityDisplay': {
    height: 20,
    width: 20,
    position: 'absolute',
    top: 0,
    left: -2,
    zIndex: 1,
    backgroundColor: 'transparent',
    color: 'rgba(0,0,0,0.87)',
    textAlign: 'left',
    padding: 2,

    '.AC-priorityCorner': {
      height: 50,
      width: 50,
      position: 'absolute',
      top: -25,
      left: -25,
      backgroundColor:
        theme.palette.mode === 'light'
          ? alpha(theme.palette.hilite.main, 0.9)
          : alpha(theme.palette.hilite.main, 0.6),
      transform: 'rotate(-45deg)',
      zIndex: -1,
    },

    '.AC-priorityIcon': {
      fontSize: '20px',
    },
  },
}));

export const Stack_StatusChips = styled(Stack)(({ theme }) => ({
  // aka AC-statusChips
  position: 'relative',
  border: '1px solid',
  borderColor: theme.palette.divider,
  overflow: 'hidden',
  padding: 0,
  backgroundColor: alpha(theme.palette.background.paper, 0.5),
  borderRadius: 24,
  minHeight: 46,

  '.AC-compactTiles &': {
    borderRadius: 18,
    // minHeight: '2.36rem',
    minHeight: 38,

    '&.AC-cameraShown': {
      // borderTopLeftRadius: 5,
      borderRadius: 4,
    },
  },

  '.AC-statusChipsRow': {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    // width: '100%',
    padding: 2,
    minHeight: 44,
    zIndex: 1,

    '.AC-compactTiles &': {
      minHeight: 36,
    },
  },

  '.AC-statusImagesRow': {
    backgroundColor: 'black',
    color: 'white',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 0,
    justifyContent: 'center',

    '.AC-compactTiles &': {
      flexDirection: 'column',
    },
  },

  '.AC-cameraDisplay': {
    // margin: '0 auto',
    // aspectRatio: '16 / 9',
    // width: 640,
    height: 'auto',

    '.AC-compactTiles &': {
      margin: 0,
      // width: '100%',

      img: {
        width: '100%',
        height: 'auto',
      },
    },
  },

  '.AC-reactPlayer': {
    width: '100% !important',
    height: 'auto !important',
  },

  '.AC-statusChips': {
    borderRadius: '1rem',
  },

  '.AC-statusChip': {
    userSelect: 'none',
    transition: 'background-color 250ms ease-out',
    color: 'inherit',
    lineHeight: '1em',
    height: 40,
    borderRadius: 500,

    '.AC-compactTiles &': {
      maxWidth: 120,
      height: 32,
    },

    '.MuiChip-label': {
      fontSize: '1rem',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),

      '.AC-compactTiles &': {
        fontSize: '0.8125rem',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
  },
}));

export const Paper_DataBox = styled(Paper)(({ theme }) => ({
  // aka AC-dataBox
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'space-between',
  gap: theme.spacing(2),
  overflow: 'hidden',
  padding: theme.spacing(1.5) + ' ' + theme.spacing(2),
  borderRadius: theme.spacing(1),
  border: 0,
  borderColor: theme.palette.action.selected,
  backgroundColor: theme.palette.action.selected,
  height: theme.spacing(12),

  '.AC-compactTiles &': {
    gap: theme.spacing(1.5),
    height: theme.spacing(8),
    borderRadius: 0,
    border: 0,
    padding: theme.spacing(1),
  },

  '.AC-dataBoxGridLiner': {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
    gap: theme.spacing(2),
    height: theme.spacing(9),
  },

  '.AC-dataHeading': {
    flexDirection: 'row',
    gap: theme.spacing(0.5),
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: theme.spacing(3),

    '.AC-compactTiles &': {
      minHeight: theme.spacing(0),
    },
  },

  '.AC-dataHeader': {
    fontSize: '1em',
    lineHeight: '1.3em',
    maxWidth: '100%',
    // alignSelf: 'start',

    '.AC-compactTiles &': {
      fontSize: 12,
    },
  },

  '.AC-dataReading': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: theme.spacing(0.75),
    alignSelf: 'end',
    width: '100%',
    minHeight: theme.spacing(3.5),

    '.AC-compactTiles &': {
      // justifyContent: 'space-between',
      gap: theme.spacing(0.25),
      minHeight: theme.spacing(2),
    },
  },

  '.AC-dataValue': {
    fontSize: '1.5em',
    lineHeight: '1.3em',
    maxWidth: '100%',

    [theme.breakpoints.down('md')]: {
      fontSize: '1.5em',
    },

    '&.AC-textVariant': {
      textTransform: 'capitalize',
    },

    '.AC-compactTiles &': {
      fontSize: 18,
      fontStretch: '75%',

      '&.AC-textVariant': {
        fontSize: 16,
      },
    },
  },

  '.AC-dataUnits': {
    textAlign: 'left',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '100%',

    '.AC-compactTiles &': {
      fontSize: 12,
      fontStretch: '50%',
    },
  },
}));

export const Box_StatusAlerts = styled(Box)(({ theme }) => ({
  // aka AC-statusAlerts
  padding: 0,
  borderTop: '2px solid',
  borderColor: alpha(theme.palette.background.paper, 0.5),
  marginTop: -2,

  '.AC-alertListDivider': {
    margin: 0,
    borderColor: alpha(theme.palette.background.paper, 0.5),
    height: 2,
  },

  '.AC-alertListItem': {
    cursor: 'pointer',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    minHeight: theme.spacing(10),
    backgroundColor: alpha(theme.palette.error.main, 0.18),
    ...(AnimationStyles.pulse(theme) as object),

    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },

    '.AC-compactTiles &': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      minHeight: theme.spacing(8),

      '.AC-infoGroup': {
        gap: theme.spacing(1),
      },
    },

    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.24),
      // animationPlayState: 'paused',
    },

    '&.AC-alertListItemWarning': {
      backgroundColor: alpha(theme.palette.hilite.main, 0.18),

      '&:hover': {
        backgroundColor: alpha(theme.palette.hilite.main, 0.24),
      },
    },

    '&.AC-oneClickResolvingMode': {
      backgroundColor: theme.palette.background.paper,
      animation: 'none',
    },

    '.AC-infoIcon': {
      justifySelf: 'center',
      width: theme.spacing(6),
      height: theme.spacing(6),
      margin: '0 ' + theme.spacing(1),

      '.AC-compactTiles &': {
        width: theme.spacing(5),
        height: theme.spacing(5),
        margin: '0 ' + theme.spacing(0.5),
      },
    },
  },

  '.AC-alertExtras': {
    flexDirection: 'row',
    gap: theme.spacing(3),
    alignItems: 'center',

    '.AC-compactTiles &': {
      flexDirection: 'column',
      // alignItems: 'end',
      gap: theme.spacing(0.25),
    },
  },

  '.AC-alertAction': {
    padding: theme.spacing(0.5) + ' ' + theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper + '!important',
    borderRadius: 50,

    '.AC-compactTiles &': {
      padding: '0 ' + theme.spacing(1.5),
      lineHeight: '1.3rem',
      fontSize: '.75rem',
    },
  },
}));

export const Box_ResizingTableStyles = styled(Box)(({ theme }) => ({
  // aka AC-resizingTable
  textAlign: 'center',
  padding: theme.spacing(2),
  color: alpha(theme.palette.warning.main, 0.66),
}));

export const Box_ListViewTableStyles = styled(Box)(({ theme }) => ({
  // aka AC-listViewTable
  '.TableCell-cell, .CellLayout-cell': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  '.TableCell-cell:first-of-type, .CellLayout-cell:first-of-type': {
    paddingLeft: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  '.TableCell-cell:last-of-type, .CellLayout-cell:last-of-type': {
    paddingRight: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(1),
    },
  },
  'tbody tr:last-of-type td': {
    borderBottom: 'none',
  },
  'tbody tr:hover': {
    cursor: 'pointer',

    td: {
      backgroundColor: theme.palette.action.hover,
    },
  },
  '.TableNoDataCell-cell': {
    padding: theme.spacing(4),
    fontStyle: 'italic',
    color: theme.palette.text.secondary,
  },
  'colgroup col:first-of-type': {
    width: 'auto !important',
  },
  '.AC-warningRow': {
    backgroundColor: alpha(theme.palette.hilite.main, 0.2),
    border: '1px solid',
    borderColor: theme.palette.warning.light,
    color: theme.palette.getContrastText(theme.palette.warning.light),
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    margin: theme.spacing(-1),
  },
}));

export const AppBar_Styled = styled(AppBar)(({ theme }) => ({
  // aka AC-appBar
  borderTop: ToolbarDivider,
  backgroundColor:
    theme.palette.mode === UserPersonTheme.light
      ? theme.palette.primary.main
      : theme.palette.primary.dark,
  color:
    theme.palette.mode === UserPersonTheme.light
      ? theme.palette.getContrastText(theme.palette.primary.main)
      : theme.palette.getContrastText(theme.palette.primary.dark),

  [theme.breakpoints.down('sm')]: {
    boxShadow: 'none',
  },

  '&.AC-actionView': {
    backgroundColor:
      theme.palette.mode === UserPersonTheme.light
        ? theme.palette.secondary.main
        : theme.palette.secondary.dark,
    color:
      theme.palette.mode === UserPersonTheme.light
        ? theme.palette.getContrastText(theme.palette.secondary.main)
        : theme.palette.getContrastText(theme.palette.secondary.dark),
  },

  '.AC-titleBlock': {
    display: 'flex',
    flex: 1,
    minWidth: 0,
    maxWidth: '100%',
    overflow: 'hidden',

    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },

    '.MuiTypography-root': {
      width: '100%',
      maxWidth: '100%',
    },

    '.MuiSvgIcon-root': {
      fontSize: theme.spacing(3.5),
    },
  },

  '.AC-appToolbarPrimaryButton': {
    minWidth: theme.spacing(8.5),

    [theme.breakpoints.down('sm')]: {
      minWidth: theme.spacing(5.5),
    },
  },

  '.AC-printAppbarLogo': {
    display: 'none',
  },
}));

export const Drawer_MainMenu = styled(Drawer)(({ theme }) => ({
  // aka AC-mainMenu

  '.AC-mainMenuHeader': {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    gap: theme.spacing(1),
    width: Layout.constants.DRAWER_WIDTH,
    height: Layout.constants.TOOLBAR_HEIGHT,
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      height: Layout.constants.TOOLBAR_HEIGHT_XS,
    },
  },

  '.MuiListItemButton-root': {
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.tertiary.main, 0.12),

      // Following causes many warning in console.
      // '.MuiListItemIcon-root:first-child': {
      //   color: theme.palette.tertiary.main,
      // },

      // Use this instead
      '.MuiSvgIcon-root:first-of-type': {
        color: theme.palette.tertiary.main,
      },

      '&:hover': {
        backgroundColor: alpha(theme.palette.tertiary.main, 0.18),
      },
    },
    '&.Mui-disabled': {
      color: theme.palette.text.disabled,
    },
  },
}));

export const Paper_PageBar = styled(Paper)(({ theme }) => ({
  // aka AC-pageBar
  zIndex: 10,
  borderRadius: theme.spacing(0),
  backgroundColor: 'hsl(187deg 20% 25%)',
  boxShadow:
    '0px 2px 4px -1px rgba(0,0,0,0.2), ' +
    '0px 4px 5px 0px rgba(0,0,0,0.14), ' +
    '0px 1px 10px 0px rgba(0,0,0,0.12)',

  '@media (min-height: 729px) and (min-width: 729px)': {
    // floats on taller space
    borderRadius: theme.spacing(1),
  },

  // '.AC-iconButton': {
  //   '.MuiSvgIcon-root': {
  //     opacity: 0.7,
  //   },
  //   '&:hover .MuiSvgIcon-root': {
  //     opacity: 1,
  //   },
  // },

  '.AC-brandButtonBox': {
    padding: theme.spacing(1),
  },

  '.AC-brandButton': {
    background: isVendorToppan()
      ? 'linear-gradient(180deg, #6992C8 0%, #1C57A5 100%)'
      : 'linear-gradient(180deg, #E74F25 0%, #CF350B 100%)',
    color: theme.palette.text.primary + '!important',
    lineHeight: '1em',
    width: 40,
    height: 40,
    padding: 0,
    minWidth: 0,
    overflow: 'hidden',
    position: 'relative',
    // margin: theme.spacing(0) + ' ' + theme.spacing(1.125),

    '.AC-brandIcon': {
      height: 36,
      width: 36,
    },
  },

  '.AC-locationButtonBox': {
    padding: theme.spacing(1) + ' ' + theme.spacing(0),
    display: 'flex',
    flex: 1,
    maxWidth: '100%',
    overflow: 'hidden',
    marginRight: theme.spacing(1),
  },

  '.AC-locationButton': {
    color: theme.palette.text.primary + '!important',
    textTransform: 'none',
    justifySelf: 'stretch',
    justifyContent: 'start',
    height: 40,
    overflow: 'hidden',
    position: 'relative',

    '&:hover': {
      backgroundColor: alpha('#ffffff', 0.08),
    },

    '.AC-locationLabel': {
      maxWidth: '100%',

      '.MuiTypography-root': {
        maxWidth: '100%',
        fontWeight: '500',
      },
    },
  },
}));

export const Paper_Highlight = styled(Paper)(({ theme }) => ({
  // aka AC-highlight
  padding: theme.spacing(2),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },

  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },

  '&.AC-default': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
  },
  '&.AC-action': {
    backgroundColor: alpha(theme.palette.action.active, 0.2),
    borderColor: theme.palette.action.active,
  },
  '&.AC-success': {
    backgroundColor: alpha(theme.palette.success.main, 0.2),
    borderColor: theme.palette.success.light,
  },
  '&.AC-info': {
    backgroundColor: alpha(theme.palette.info.main, 0.2),
    borderColor: theme.palette.info.light,
  },
  '&.AC-warning': {
    backgroundColor: alpha(theme.palette.warning.main, 0.2),
    borderColor: theme.palette.warning.light,
  },
  '&.AC-error': {
    backgroundColor: alpha(theme.palette.error.main, 0.2),
    borderColor: theme.palette.error.light,
  },
  '&.AC-disabled': {
    backgroundColor: alpha(theme.palette.text.disabled, 0.2),
    borderColor: theme.palette.text.disabled,
  },
}));

export const Paper_InfoBox = styled(Paper)(({ theme }) => ({
  // aka AC-infoBox
  borderColor: alpha(theme.palette.warning.main, 0.37),
  backgroundColor: alpha(theme.palette.hilite.main, 0.12),
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

export const Box_StatusLED = styled(Box)(() => ({
  // aka AC-statusLED
  '.AC-led': {
    opacity: 0.75,
  },
  '.AC-ledSpin': {
    // animation: `spin 1s infinite`,
    ...(AnimationStyles.spin() as object),
  },
}));

export const Card_AlertSettings = styled(Card)(({ theme }) => ({
  // aka AC-alertSettingsCard
  height: '100%',
  width: '100%',
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  borderRadius: theme.spacing(1),

  '.MuiCardContent-root': {
    // padding: theme.spacing(2),
    paddingTop: 0,
  },

  '.MuiCardActions-root': {
    padding: 0,
  },
}));

export const Stack_AlertSettings = styled(Stack)(({ theme }) => ({
  // aka AC-alertSettingsInfo

  '&.AC-readOnlyDisplay': {
    color: theme.palette.text.secondary,
    gap: theme.spacing(1),

    '.MuiTypography-root': {
      lineHeight: '1.5em',
    },
  },
  '.AC-readOnlyKey': {
    display: 'none',
  },
  '.AC-readOnlyValue': {},

  '&.AC-settingList': {
    gap: theme.spacing(4),
  },
  '.AC-settingItem': {
    gap: theme.spacing(1),
  },
  '.AC-settingHeader': {
    gap: theme.spacing(1),
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'left',

    '&.AC-offDescription': {
      color: theme.palette.text.disabled,
      fontStyle: 'italic',
    },
  },
  '.AC-infoBox': {
    borderColor: alpha(theme.palette.warning.main, 0.37),
    backgroundColor: alpha(theme.palette.hilite.main, 0.12),
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  '.AC-sliderContainer': {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(1),
  },
  '.AC-radioGroup': {
    border: '1px solid',
    borderColor:
      theme.palette.mode === UserPersonTheme.light
        ? 'rgba(0, 0, 0, 0.23)'
        : 'rgba(255, 255, 255, 0.23)',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    padding: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  '.AC-radioGroupItem': {
    margin: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export const Card_SleepReport = styled(Card)(({ theme }) => ({
  // aka AC-sleepReport
  '.AC-paddedCell': {
    padding: theme.spacing(1),
  },
  '.AC-cellGroup': {
    ...(TextStyles.ellipsis() as object),
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.25),
    alignItems: 'baseline',
  },
  '.AC-paddedCell .AC-cellGroup': {
    gap: theme.spacing(1),
  },
  '.AC-cellLabel': {
    fontSize: '.9em',
    fontWeight: '700',
  },
  '.AC-cellValue': {
    fontSize: '2em',
    fontWeight: '300',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5em',
    },
  },
  '.AC-reportTable .AC-cellValue': {
    fontSize: '1.2em',
    fontWeight: '700',
  },
  '.AC-cellUnits': {
    fontSize: '1em',
    fontWeight: '700',
    color: theme.palette.text.disabled,
    [theme.breakpoints.down('md')]: {
      fontSize: '.9em',
    },
  },
  '.AC-reportTable .AC-cellUnits': {
    fontSize: '1em',
    fontWeight: '700',
  },
  '.AC-reportTable': {
    thead: {
      backgroundColor: theme.palette.background.paper,
    },
    'th:not(:last-child), td:not(:last-child)': {
      paddingRight: 0,
    },
    th: {
      ...(TextStyles.ellipsis() as object),
      backgroundColor: theme.palette.background.paper,
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    td: {
      border: 0,
      paddingBottom: theme.spacing(0),
    },
    '.AC-withSummary .highcharts-container': {
      overflow: 'hidden',
      marginBottom: theme.spacing(-2.75),
    },
  },
}));

export const Stack_SleepMeter = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: 2,

  '.AC-sleepStep': {
    width: 6,
    height: 12,
    backgroundColor: alpha(theme.palette.text.primary, 0.1),
    border: '1px solid',
    borderColor: theme.palette.divider,
    transition: 'background-color 250ms ease-out',

    '&.AC-step0': {
      // awake
      backgroundColor: theme.palette.awake.dark,
    },
    '&.AC-step1': {
      // light
      backgroundColor: theme.palette.rem.dark,
    },
    '&.AC-step2': {
      // mid-light
      backgroundColor: '#3CC8FD',
    },
    '&.AC-step3': {
      // mid-deep
      backgroundColor: '#10B0F4',
    },
    '&.AC-step4': {
      // deep
      backgroundColor: '#0083E8',
    },
  },
}));

////////////////////////////////////////////////
// Exported Styles
////////////////////////////////////////////////

export const ToolbarButtonStyles = {
  button: (theme: Theme): CSSInterpolation => ({
    alignSelf: 'stretch',
    minWidth: Layout.constants.TOOLBAR_BUTTON_WIDTH,
    borderRadius: 0,
    border: 0,
    maxWidth: 120,

    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
      width: 'auto',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },

    '.MuiSvgIcon-root': {
      fontSize: theme.spacing(3),
    },
  }),
  stack: (theme: Theme): CSSInterpolation => ({
    maxWidth: '100%',
    gap: theme.spacing(0.5),
  }),
  label: (theme: Theme): CSSInterpolation => ({
    color: '#ffffff',
    lineHeight: '1.2em',
    maxWidth: '100%',
    fontWeight: 500,

    '.MuiAppBar-root &': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }),
};

export const SleepReportStyles = {
  reportGrid: (): CSSInterpolation => ({}),
  gridCell: (theme: Theme): CSSInterpolation => ({
    padding: theme.spacing(1),
  }),
  cellGroup: (): CSSInterpolation => ({
    ...(TextStyles.ellipsis() as object),
  }),
  cellLabel: (): CSSInterpolation => ({
    fontSize: '.9em',
    fontWeight: '700',
  }),
  cellValue: (theme: Theme): CSSInterpolation => ({
    fontSize: '2em',
    fontWeight: '300',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5em',
    },

    'table &': {
      fontSize: '1.2em',
      fontWeight: '700',
    },
  }),
  cellUnits: (theme: Theme): CSSInterpolation => ({
    fontSize: '1em',
    fontWeight: '700',
    color: theme.palette.text.disabled,
    [theme.breakpoints.down('md')]: {
      fontSize: '.9em',
    },

    'table &': {
      fontSize: '1em',
      fontWeight: '700',
    },
  }),
  durationGroup: (): CSSInterpolation => ({}),
  durationBreakdown: (): CSSInterpolation => ({}),
  reportTableHead: (theme: Theme): CSSInterpolation => ({
    boxShadow: '0 4px 8px ' + theme.palette.background.paper,
  }),
  reportTable: (theme: Theme): CSSInterpolation => ({
    thead: {
      backgroundColor: theme.palette.background.paper,
    },
    'th:not(:last-child), td:not(:last-child)': {
      paddingRight: 0,
    },
    th: {
      ...(TextStyles.ellipsis() as object),
      backgroundColor: theme.palette.background.paper,
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    td: {
      border: 0,
      paddingBottom: theme.spacing(0),
    },
  }),
  reportTableCell: (): CSSInterpolation => ({
    px: 0,
  }),
  chartCell: (): CSSInterpolation => ({
    padding: 0,
  }),
  dayDivider: (): CSSInterpolation => ({
    padding: 0,
  }),
};

export const CameraStyles = {
  box: (theme: Theme): CSSInterpolation => ({
    // aspectRatio: '640 / 360',
    // borderRadius: theme.spacing(0.5),
    overflow: 'hidden',
    // position: 'relative',
    // backgroundColor: alpha(theme.palette.divider, 0.05),
    backgroundColor: 'black',
    color: 'white',
    boxShadow: '0 0 4px #cccccc',

    'svg[data-testid="BrokenImageIconIcon"]': {
      // aspectRatio: '640 / 360',
      aspectRatio: 'inherit',
      maxHeight: '50%',
      padding: theme.spacing(2),
    },

    '&:hover': {
      cursor: 'pointer',
    },
  }),
  noImageBox: (): CSSInterpolation => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: 'inherit',
  }),
  closeButton: (): CSSInterpolation => ({
    position: 'absolute',
    top: 2,
    right: 2,
    height: 40,
    width: 40,

    '.AC-compactTiles &': {
      height: 32,
      width: 32,
    },

    '.MuiBox-root, .MuiCircularProgress-root, .MuiIconButton-root': {
      width: '100%',
      height: '100%',
    },
  }),
  openButton: (): CSSInterpolation => ({
    padding: '.25rem',
    lineHeight: '1rem',
    height: 40,
    width: 40,
    color: 'inherit',

    '.AC-compactTiles &': {
      height: 32,
      width: 32,
    },
  }),
  statusBox: (): CSSInterpolation => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  }),
};

////////////////////////////////////////////////
// Possibly Obsolete
////////////////////////////////////////////////

export const AlertSettingsStyles = {
  editButton: (theme: Theme): CSSInterpolation => ({
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      color: theme.palette.primary.light,
    },
  }),
  header: (theme: Theme): CSSInterpolation => ({
    margin: theme.spacing(0.5),
  }),
  settingsList: (theme: Theme): CSSInterpolation => ({
    paddingTop: theme.spacing(0),
  }),
};

export const Stack_RangeMeter = styled(Stack)(({ theme }) => ({
  '.AC-meterBox': {
    width: '100%',
    position: 'relative',
    marginTop: theme.spacing(3.5),
    '-webkit-user-select': 'none' /* Chrome all / Safari all */,
    '-moz-user-select': 'none' /* Firefox all */,
    '-ms-user-select': 'none' /* IE 10+ */,
    'user-select': 'none',
  },
  '.AC-mainBar': {
    width: '100%',
    backgroundColor: theme.palette.error.main,
    height: 5,
    position: 'relative',
  },
  '.AC-mainBarCap': {
    width: 1,
    backgroundColor: theme.palette.error.main,
    top: -3,
    height: 11,
    position: 'absolute',
  },
  '.AC-rangeBar': {
    backgroundColor: theme.palette.success.main,
    height: 9,
    top: -2,
    position: 'absolute',
    zIndex: 2,
  },
  '.AC-rangeBarCap': {
    width: 2,
    height: 17,
    top: -6,
    backgroundColor: theme.palette.success.main,
    position: 'absolute',
    zIndex: 2,
  },
  '.AC-dragEdges': {
    position: 'relative',
    top: -25,
  },
  '.AC-dragEdge': {
    cursor: 'move',
    position: 'absolute',
    zIndex: 10,
    width: 50,
    height: 50,
  },
  '.AC-rangeBarCapEdit': {
    width: 18,
    height: 18,
    top: -7,
    backgroundColor: theme.palette.success.main,
    borderRadius: 18,
    zIndex: 2,
    marginLeft: 16,
    marginTop: 14,
  },
  '.AC-rangeBarCapEditLeft': {},
  '.AC-rangeLabelMin': {
    position: 'absolute',
    top: -30,
    marginLeft: 3,
    zIndex: 3,
  },
  '.AC-rangeLabelMax': {
    position: 'absolute',
    top: -30,
    textAlign: 'right',
    zIndex: 3,
  },
  '.AC-rangeLabelMaxAlign': {
    position: 'absolute',
    right: 3,
  },
  '.AC-valueIndicator': {
    position: 'absolute',
    top: 6,
    zIndex: 15,
    width: 60,
  },
  '.AC-warningIcon': {
    width: 24,
    height: 24,
    marginBottom: -4,
  },
  '.AC-warningIconLabel': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),
    paddingLeft: 4,
    paddingRight: 4,
  },
}));
