"use strict";
/**
 * Service that manages AiSleep server configuration.
 *
 * N.B. Some of the functions use the AC API to communicate with the
 * AiSleep server, and some communicate directly with AiSleep. All
 * functions that start with 'aisleep' communicate directly with the
 * AiSleep server.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.aisleepRemoveSensor = exports.aisleepAssignSensorToResident = exports.aisleepDetachSensor = exports.aisleepRegisterSensor = exports.aisleepUpdateAlertSettings = exports.mapSensorAlertSettingsToACApiAisleepDeviceSettings = exports.mapACApiDeviceSettingsToAiSleepApi = exports.mapAisleepDeviceSettingsToAC = exports.mapAisleepDeviceToAC = exports.aisleepEnableDeviceAlarms = exports.aisleepDisableDeviceAlarms = exports.aisleepResolveDeviceAlarm = exports.getVitals = exports.getAisleepUserConfigRiskLevel = exports.defaultAisleepAlertSettings = exports.aisleepDefaultAlarmSettings = exports.aisleepDeleteDevice = exports.aisleepUpdateDevice = exports.aisleepRegisterDevice = exports.aisleepFindDevices = exports.aisleepFindDevice = exports.aisleepFindDeviceIds = exports.aisleepFindDeviceInfo = exports.aisleepFindDevicesInfo = exports.aisleepDeleteFacility = exports.aisleepFindFacility = exports.aisleepFindFacilities = exports.aisleepUpdateFacility = exports.aisleepRegisterFacility = exports.aisleepConfigureFacility = exports.aisleepLoginWithCareCenterSettings = exports.aisleepTokenSession = exports.aisleepLogin = exports.getAisleepTokenUsername = exports.validateAisleepServerToken = exports.validateAisleepServerCredentials = exports.validateServerConfig = exports.getAisleepPassword = void 0;
const aisleep = __importStar(require("aisleep-api/dist/api"));
const http_status_codes_1 = require("http-status-codes");
const jose = __importStar(require("jose"));
// import moment from 'moment-timezone';
const errors_1 = require("../errors");
const ErrorMessage_1 = __importStar(require("../errors/ErrorMessage"));
const logger_1 = require("../logger");
const mappers_1 = require("../mappers");
const models_1 = require("../models");
const TimeRange_1 = require("../models/TimeRange");
const types = __importStar(require("../types"));
const CareCenterService_1 = require("./CareCenterService");
const settings_1 = require("../sync/util/settings");
const logger = (0, logger_1.getLogger)('/services/AisleepService');
/**
 * Check if error is a fetch response object.
 *
 * @param e
 * @returns
 */
const isResponse = (e) => {
    const response = e;
    return (response.status !== undefined &&
        response.statusText !== undefined &&
        (response.ok === true || response.ok === false) &&
        typeof response.json === 'function');
};
const errorMessage = (e, code, message) => __awaiter(void 0, void 0, void 0, function* () {
    if ((0, ErrorMessage_1.isErrorMessage)(e)) {
        return e;
    }
    else if (isResponse(e)) {
        return errorMessageFromResponse(e, code, message);
    }
    else {
        return errorMessageFromUnknown(e, code, message);
    }
});
/**
 * A generic error handler for calls to Aisleep API. If an error occured during the API
 * request, then returns an error (ACError).
 *
 * @param response response object from an API call.
 */
const errorMessageFromResponse = (response, code, message) => response
    .json()
    .then((data) => new ErrorMessage_1.default(response.status, code ? code : errors_1.ACErrorCodes.ErrorAisleep, message ? message : 'an aisleep error occurred', { status: response.status, statusText: response.statusText }, data));
const errorMessageFromUnknown = (e, code, message) => {
    return new ErrorMessage_1.default(http_status_codes_1.StatusCodes.INTERNAL_SERVER_ERROR, code ? code : errors_1.ACErrorCodes.ErrorAisleep, message ? message : 'an unknown aisleep error occured', {}, e);
};
const lookupSkey = (db) => {
    const skey = db.env.aisleep.skey;
    if (!skey || skey.length === 0) {
        logger.notice('AiSleep skey is not configured');
    }
    return skey ? skey : '';
};
const getAisleepCredentials = (db, careCenter) => {
    const center = (0, CareCenterService_1.isCareCenter)(careCenter) ? careCenter.data : careCenter;
    const { aisleep } = center;
    if (!db.careCenters.supportsAisleep(center)) {
        logger.notice('care center ' + center.name + 'does not support AISLEEP');
        return undefined;
    }
    if (!aisleep || !aisleep.server) {
        logger.notice('care center ' +
            center.name +
            'does not have AISLEEP server settings - cannot get credentials');
        return undefined;
    }
    const { url, username, password } = aisleep.server;
    const credentials = {
        url,
        username,
        password,
        skey: lookupSkey(db),
    };
    return credentials;
};
const getAisleepPassword = (db, careCenter) => {
    const credentials = getAisleepCredentials(db, careCenter);
    if (credentials) {
        return credentials.password;
    }
    return undefined;
};
exports.getAisleepPassword = getAisleepPassword;
/**
 * Convenience function to validate AiSleep server settings for a care center.
 * @param db
 * @param center
 * @returns true if config params are valid.
 */
const validateServerConfig = (db, careCenter) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const center = (0, CareCenterService_1.isCareCenter)(careCenter) ? careCenter.data : careCenter;
    try {
        if (!((_a = center.aisleep) === null || _a === void 0 ? void 0 : _a.useProxy)) {
            yield (0, exports.aisleepLoginWithCareCenterSettings)(db, careCenter);
        }
        return true;
    }
    catch (e) {
        if ((0, errors_1.isError)(e)) {
            logger.info('validate server config error: ' + e.message);
        }
        return false;
    }
});
exports.validateServerConfig = validateServerConfig;
/**
 * Throws an error if Aisleep server URL and/or token are missing.
 *
 * @param credentials
 */
const validateAisleepServerCredentials = (url, username, password) => {
    if (!url || url === 'null') {
        throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.BAD_REQUEST, errors_1.ACErrorCodes.ErrorMissingParameter, 'url');
    }
    if (!username || username === 'null') {
        throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.BAD_REQUEST, errors_1.ACErrorCodes.ErrorMissingParameter, 'username');
    }
    if (!password || password === 'null') {
        throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.BAD_REQUEST, errors_1.ACErrorCodes.ErrorMissingParameter, 'password');
    }
};
exports.validateAisleepServerCredentials = validateAisleepServerCredentials;
/**
 * Throws an error if Aisleep server URL and/or token are missing.
 *
 * @param serverToken
 */
const validateAisleepServerToken = (url, token) => {
    if (!url || url === 'null') {
        throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.BAD_REQUEST, errors_1.ACErrorCodes.ErrorMissingParameter, 'url');
    }
    if (!token || token === 'null') {
        throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.BAD_REQUEST, errors_1.ACErrorCodes.ErrorMissingParameter, 'token');
    }
};
exports.validateAisleepServerToken = validateAisleepServerToken;
const getAisleepTokenUsername = (token) => {
    const claims = jose.decodeJwt(token);
    if (claims.sub) {
        return claims.sub;
    }
    return '';
};
exports.getAisleepTokenUsername = getAisleepTokenUsername;
/**
 * Connects AiSleep server and returns session.
 *
 * @param db
 * @param url AiSleep server URL
 * @param username AiSleep server username
 * @param password AiSleep server password
 * @returns session to communicate with AiSleep
 */
const aisleepLogin = (db, url, username, password) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        (0, exports.validateAisleepServerCredentials)(url, username, password);
        const session = yield aisleep.login(url, username, password);
        if (session.loginStatus.success === 'true') {
            return {
                url,
                username,
                token: session.loginStatus.access_token,
                skey: lookupSkey(db),
                api: session,
                db,
            };
        }
        else {
            throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.UNAUTHORIZED, errors_1.ACErrorCodes.ErrorAisleepUnableToLogin, 'Could not log into AiSleep server');
        }
    }
    catch (e) {
        throw yield errorMessage(e, errors_1.ACErrorCodes.ErrorAisleepUnableToLogin, 'unable to login to AiSleep server');
    }
});
exports.aisleepLogin = aisleepLogin;
const aisleepTokenSession = (db, url, token) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        (0, exports.validateAisleepServerToken)(url, token);
        const session = yield aisleep.useToken(url, token);
        if (session.loginStatus.success === 'true') {
            return {
                url,
                username: (0, exports.getAisleepTokenUsername)(token),
                token: session.loginStatus.access_token,
                skey: lookupSkey(db),
                api: session,
                db,
            };
        }
        else {
            throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.UNAUTHORIZED, errors_1.ACErrorCodes.ErrorAisleepUnableToLogin, 'Could not log into AiSleep server');
        }
    }
    catch (e) {
        throw yield errorMessage(e, errors_1.ACErrorCodes.ErrorAisleepUnableToLogin, 'unable to login to AiSleep server');
    }
});
exports.aisleepTokenSession = aisleepTokenSession;
/**
 * Connects directly to the AiSleep server and logs in.
 * Uses the AiSleep credentials configured in the care center.
 * Throws error if credentials are missing or cannot log in.
 *
 * @param db
 * @param center
 * @returns then session object that can be used to communicate with AiSleep
 * @throws error if unable to log in or credentials are not available
 */
const aisleepLoginWithCareCenterSettings = (db, careCenter) => __awaiter(void 0, void 0, void 0, function* () {
    const center = (0, CareCenterService_1.isCareCenter)(careCenter) ? careCenter.data : careCenter;
    const credentials = getAisleepCredentials(db, center);
    if (!credentials) {
        throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.BAD_REQUEST, errors_1.ACErrorCodes.ErrorAisleepNoCredentials, 'AiSleep server credentials are not configured for care center: ' + center.name, { name: center.name });
    }
    const { url, username, password } = credentials;
    return (0, exports.aisleepLogin)(db, url, username, password);
});
exports.aisleepLoginWithCareCenterSettings = aisleepLoginWithCareCenterSettings;
/**
 * Update the configuration in the AiSleep server so that:
 *
 * 1) a facility in AiSleep is mapped to the AC care center.
 * 2) all sensors registered with the care center appear in the AiSleep facility.
 *
 * Communicates directly with the AiSleep server.
 *
 * @param db
 * @param center
 * @returns
 */
const aisleepConfigureFacility = (db, careCenter, callbackUrl) => __awaiter(void 0, void 0, void 0, function* () {
    var _b, _c;
    const centerData = (0, CareCenterService_1.isCareCenter)(careCenter) ? careCenter.data : careCenter;
    // If update a center that already exists...
    const sensors = (0, CareCenterService_1.isCareCenter)(careCenter)
        ? yield (yield db.sensors.getCareCenterSensors(careCenter)).filter((s) => s.data.sensorType === models_1.SensorType.AISLEEP)
        : [];
    // if AiSleep support is being disabled...
    if (!db.careCenters.supportsAisleep(centerData)) {
        // check that there are no active sensors in AC...
        const supportedAisleep = careCenter !== undefined && db.careCenters.supportsAisleep(centerData);
        if (sensors.length > 0 && supportedAisleep) {
            throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.BAD_REQUEST, types.acApi.ErrorMessageCodeEnum.ErrorAisleepFacilityHasActiveSensors, 'Care center (' +
                centerData.name +
                ') has active AiSleep sensors. ' +
                'These should be removed before AiSleep support can be disabled for the center.', undefined, {
                careCenter: centerData.name,
                sensors: sensors.map((s) => ({ name: s.data.name, aisleep: s.data.aisleep })),
            });
        }
        // no need to do anything else
        return centerData;
    }
    if ((_b = centerData.aisleep) === null || _b === void 0 ? void 0 : _b.useProxy) {
        // when using proxy no further checks are necessary
        return centerData;
    }
    const aisleepConfig = centerData.aisleep;
    if (!aisleepConfig || !aisleepConfig.server) {
        throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.BAD_REQUEST, errors_1.ACErrorCodes.ErrorAisleepCenterNotLinked, 'Care center (' +
            centerData.name +
            ') is missing aisleep server configuration. ' +
            'Cannot update AiSleep server configuration.');
    }
    // const callbackUrl = lookupCallbackUrl(db);
    if (!callbackUrl) {
        throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.INTERNAL_SERVER_ERROR, errors_1.ACErrorCodes.ErrorInvalidConfiguration, 'System configuration error. An AiSleep callback url is required.');
    }
    logger.debug('aisleepConfigureFacility', {
        name: centerData.name,
        callbackUrl,
        facilityId: (_c = centerData.aisleep) === null || _c === void 0 ? void 0 : _c.facilityId,
    });
    // Login to Aisleep server...
    const session = yield (0, exports.aisleepLoginWithCareCenterSettings)(db, centerData);
    // Check if facility with matching id exists...
    const records = (yield session.api.home.getHomes({ page: 1, limit: 1000 })).records;
    const facilities = records ? records : [];
    if (facilities.length === 0) {
        logger.debug('aisleepConfigureFacility - registering new facility in AiSleep', {
            name: centerData.name,
            callbackUrl,
        });
        const facility = yield (0, exports.aisleepRegisterFacility)(session, centerData.name, callbackUrl);
        aisleepConfig.facilityId = facility.id;
    }
    else {
        let facility = undefined;
        if (aisleepConfig.facilityId) {
            facility = facilities.find((r) => r.id === aisleepConfig.facilityId);
        }
        if (!facility) {
            // lookup by name...
            facility = facilities.find((r) => r.homename.toLowerCase() === centerData.name.toLowerCase());
        }
        if (!facility) {
            // register new facility
            logger.debug('aisleepConfigureFacility - registering new facility in AiSleep', {
                name: centerData.name,
                callbackUrl,
            });
            const f = yield (0, exports.aisleepRegisterFacility)(session, centerData.name, callbackUrl);
            aisleepConfig.facilityId = f.id;
        }
        else {
            logger.debug('aisleepConfigureFacility - updating facility in AiSleep', {
                name: centerData.name,
                callbackUrl,
                facilityId: facility.id,
            });
            aisleepConfig.facilityId = facility.id;
            if (facility.callbackurl !== callbackUrl || facility.homename !== centerData.name) {
                facility.callbackurl = callbackUrl;
                facility.homename = centerData.name;
                yield (0, exports.aisleepUpdateFacility)(session, aisleepConfig.facilityId, centerData.name, callbackUrl);
            }
        }
    }
    // The facility id may have changed, if so, move all sensors to that facility...
    const infos = (yield (0, exports.aisleepFindDevicesInfo)(session)).filter((info) => sensors.find((s) => s.data.aisleep !== null && s.data.aisleep.serialNumber === info.deviceid) !== undefined);
    if (logger.isDebugEnabled()) {
        logger.debug('aisleepConfigureFacility', {
            name: centerData.name,
            facilityId: aisleepConfig.facilityId,
            sensors: sensors.map((s) => ({ name: s.data.name, aisleep: s.data.aisleep })),
            infos,
        });
    }
    const statuses = yield Promise.all(infos.map((info) => {
        if (info.homeid !== aisleepConfig.facilityId && aisleepConfig.facilityId !== null) {
            logger.debug('aisleepConfigureFacility - moving existing sensor to facility', {
                name: centerData.name,
                deviceId: info.deviceid,
                facilityId: aisleepConfig.facilityId,
            });
            // move it
            info.homeid = aisleepConfig.facilityId;
            return session.api.device.updateDeviceInfo({
                deviceInfoWithId: info,
            });
        }
    }));
    for (const status of statuses) {
        if (status && status.success !== 'true') {
            logger.warning('Failed to move device to new AiSleep home', status);
        }
    }
    // Check that all sensors registered in the AC care center are also
    // present in the AiSleep server.
    const checks = [];
    if (aisleepConfig.facilityId !== null) {
        for (const sensor of sensors) {
            if (sensor.data.aisleep) {
                const info = infos.find((i) => sensor.data.aisleep !== null && i.deviceid === sensor.data.aisleep.serialNumber);
                if (!info) {
                    // register the device
                    let sensorSettings = undefined;
                    if (sensor.data.residentRef) {
                        sensorSettings = yield session.db.sensorAlertSettings.getByResidentRef(sensor.data.residentRef);
                        if (sensorSettings) {
                            sensorSettings.data.aisleep = (0, exports.defaultAisleepAlertSettings)();
                            yield session.db.sensorAlertSettings.update(sensorSettings);
                        }
                        else {
                            sensorSettings = yield session.db.sensorAlertSettings.add({
                                residentRef: sensor.data.residentRef,
                                sensingWave: null,
                                aisleep: (0, exports.defaultAisleepAlertSettings)(),
                            });
                        }
                    }
                    logger.debug('aisleepConfigureFacility - registering AC sensor into AiSleep', {
                        name: centerData.name,
                        sensor: sensor.data.aisleep,
                        facilityId: aisleepConfig.facilityId,
                    });
                    checks.push((0, exports.aisleepRegisterDevice)(session, sensor.data.aisleep.serialNumber, aisleepConfig.facilityId, (0, exports.mapSensorAlertSettingsToACApiAisleepDeviceSettings)(sensorSettings === null || sensorSettings === void 0 ? void 0 : sensorSettings.data.aisleep)));
                }
                else if (info.homeid !== aisleepConfig.facilityId) {
                    info.homeid = aisleepConfig.facilityId;
                    logger.debug('aisleepConfigureFacility - changing sensor facility', {
                        name: centerData.name,
                        deviceId: info.deviceid,
                        facilityId: aisleepConfig.facilityId,
                    });
                    checks.push(session.api.device.updateDeviceInfo({
                        deviceInfoWithId: info,
                    }));
                }
            }
        }
    }
    yield Promise.all(checks);
    centerData.aisleep = aisleepConfig;
    return centerData;
});
exports.aisleepConfigureFacility = aisleepConfigureFacility;
const aisleepRegisterFacility = (session, name, callbackUrl, address) => __awaiter(void 0, void 0, void 0, function* () {
    logger.debug('aisleepRegisterFacility');
    const duplicate = (yield (0, exports.aisleepFindFacilities)(session)).find((h) => h.name.toLowerCase() === name.toLocaleLowerCase());
    if (duplicate) {
        throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.CONFLICT, errors_1.ACErrorCodes.ErrorNameAlreadyInUse, 'A facility with that name already exists.', {
            url: session.url,
            name,
            facilityId: duplicate.id,
        });
    }
    const status = yield session.api.home.createHome({
        homeRecordData: {
            homename: name,
            callbackurl: callbackUrl,
            address: address ? address : '-',
            // defaults for AC
            pushtype: '1,3,4',
            pushTypes: [aisleep.PushType.NUMBER_1, aisleep.PushType.NUMBER_3, aisleep.PushType.NUMBER_4],
        },
    });
    logger.debug('registerFacility', { name, status: status });
    if (status.success !== 'true') {
        throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.INTERNAL_SERVER_ERROR, errors_1.ACErrorCodes.ErrorAisleep, 'Facility could not be created in the AiSleep server: ' + name, undefined, {
            url: session.url,
            name,
            status,
        });
    }
    const facility = (yield (0, exports.aisleepFindFacilities)(session)).find((f) => f.name === name);
    if (facility) {
        return facility;
    }
    else {
        throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.INTERNAL_SERVER_ERROR, errors_1.ACErrorCodes.ErrorAisleep, 'Facility (' + name + ') was registered in AC but facility was not subsequently found.', undefined, { url: session.url, facilityName: name });
    }
});
exports.aisleepRegisterFacility = aisleepRegisterFacility;
/**
 * Updates facility (home) data in AiSleep.
 *
 * @param session
 * @param name
 * @param callbackUrl
 * @param address
 * @returns
 */
const aisleepUpdateFacility = (session, facilityId, name, callbackUrl, address) => __awaiter(void 0, void 0, void 0, function* () {
    logger.debug('aisleepUpdateFacility');
    const facility = yield (0, exports.aisleepFindFacility)(session, facilityId);
    if (!facility) {
        throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.NOT_FOUND, errors_1.ACErrorCodes.ErrorAisleepFacilityNotFound, 'Facility (' + facilityId + ') does not exist.', undefined, { url: session.url, facilityId });
    }
    facility.name = name ? name : facility.name;
    facility.callbackUrl = callbackUrl ? callbackUrl : facility.callbackUrl;
    facility.address = address ? address : facility.address;
    const status = yield session.api.home.updateHome({
        homeRecord: {
            id: facilityId,
            homename: facility.name,
            callbackurl: facility.callbackUrl,
            address: facility.address ? facility.address : 'n/a',
            // defaults for AC
            pushtype: '1,3,4',
            pushTypes: [aisleep.PushType.NUMBER_1, aisleep.PushType.NUMBER_3, aisleep.PushType.NUMBER_4],
        },
    });
    if (status.success !== 'true') {
        throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.INTERNAL_SERVER_ERROR, errors_1.ACErrorCodes.ErrorAisleep, 'Facility could not be updated in the AiSleep server: ' + name, undefined, {
            url: session.url,
            facilityId,
            name,
            status,
        });
    }
    return facility;
});
exports.aisleepUpdateFacility = aisleepUpdateFacility;
/**
 * Connects to AiSleep server and retrieves list of facilities.
 *
 * @param login
 * @returns
 */
const aisleepFindFacilities = (session) => __awaiter(void 0, void 0, void 0, function* () {
    const facilities = [];
    const homes = yield session.api.home.getHomes({ page: 1, limit: 1000 });
    if (homes.records !== undefined) {
        homes.records
            .map((h) => mappers_1.aisleepFacilityMapper.aisleepToApi(h))
            .forEach((f) => {
            if (f !== undefined) {
                facilities.push(f);
            }
        });
    }
    return facilities;
});
exports.aisleepFindFacilities = aisleepFindFacilities;
/**
 * Finds a facility in the AiSleep server.
 *
 * @param session
 * @param facilityId
 * @returns
 */
const aisleepFindFacility = (session, facilityId) => __awaiter(void 0, void 0, void 0, function* () {
    const homes = yield (0, exports.aisleepFindFacilities)(session);
    const home = homes.find((h) => h.id === facilityId);
    return home;
});
exports.aisleepFindFacility = aisleepFindFacility;
/**
 * Deletes a facility in the AiSleep server.
 *
 * @param session
 * @param name
 * @param callbackUrl
 * @returns
 */
const aisleepDeleteFacility = (session, facilityId) => __awaiter(void 0, void 0, void 0, function* () {
    logger.debug('aisleepRegisterFacility');
    const facility = (yield (0, exports.aisleepFindFacilities)(session)).find((h) => h.id === facilityId);
    if (!facility) {
        throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.NOT_FOUND, errors_1.ACErrorCodes.ErrorAisleepFacilityNotFound, 'Facility (' + facilityId + ') does not exist.', undefined, { url: session.url, facilityId });
    }
    const devices = yield (yield (0, exports.aisleepFindDevicesInfo)(session)).filter((d) => d.homeid === facilityId);
    if (devices.length > 0) {
        // don't delete a facility with active sensors
        throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.FORBIDDEN, errors_1.ACErrorCodes.ErrorAisleepFacilityHasActiveSensors, 'Not allowed to delete a facility that has active sensors.', undefined, {
            url: session.url,
            facilityId,
            sensors: devices.map((d) => d.deviceid),
        });
    }
    const status = yield session.api.home.deleteHome({
        id: facilityId,
        username: session.username,
    });
    logger.debug('deleteFacility', { facilityId, status });
    if (status.success !== 'true') {
        throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.INTERNAL_SERVER_ERROR, errors_1.ACErrorCodes.ErrorAisleep, 'Facility (' + facilityId + ') could not be deleted.', undefined, { url: session.url, facilityId, status });
    }
});
exports.aisleepDeleteFacility = aisleepDeleteFacility;
const aisleepFindDevicesInfo = (session) => __awaiter(void 0, void 0, void 0, function* () {
    const records = [];
    const facilities = yield (0, exports.aisleepFindFacilities)(session);
    const pages = yield Promise.all(facilities.map((f) => session.api.device.getDeviceInfoPage({
        homeid: f.id.toString(),
        page: '1',
        limit: '1000',
    })));
    for (const page of pages) {
        if (page.records) {
            records.push(...page.records);
        }
    }
    logger.debug('findDevicesInfo: ' + records.length);
    return records;
});
exports.aisleepFindDevicesInfo = aisleepFindDevicesInfo;
const aisleepFindDeviceInfo = (session, deviceId) => __awaiter(void 0, void 0, void 0, function* () {
    const infos = yield (0, exports.aisleepFindDevicesInfo)(session);
    const info = infos.find((i) => i.deviceid === deviceId);
    return info;
});
exports.aisleepFindDeviceInfo = aisleepFindDeviceInfo;
const aisleepFindDeviceIds = (session) => __awaiter(void 0, void 0, void 0, function* () {
    const devicesInfo = yield (0, exports.aisleepFindDevicesInfo)(session);
    const deviceIds = devicesInfo.map((d) => d.deviceid);
    if (logger.isDebugEnabled()) {
        logger.debug('findDeviceIds: ', deviceIds);
    }
    return deviceIds;
});
exports.aisleepFindDeviceIds = aisleepFindDeviceIds;
/**
 * If device is not found, an exception is thrown.
 * @param session
 * @param deviceId
 * @returns
 */
const aisleepFindDevice = (session, deviceId) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield session.api.device.getDevice({ deviceId });
    if (result.success === 'true') {
        return result.data;
    }
    return undefined;
});
exports.aisleepFindDevice = aisleepFindDevice;
/**
 * If device is not found, an exception is thrown.
 * @param session
 * @returns
 */
const aisleepFindDevices = (session) => __awaiter(void 0, void 0, void 0, function* () {
    const ids = yield (0, exports.aisleepFindDeviceIds)(session);
    const found = yield Promise.all(ids.map((id) => {
        const device = (0, exports.aisleepFindDevice)(session, id);
        if (!device) {
            logger.notice('Device with id (' + id + ') could not be queried from the AiSleep server.');
        }
        return device;
    }));
    const devices = [];
    for (const device of found) {
        if (device) {
            devices.push(device);
        }
    }
    return devices;
});
exports.aisleepFindDevices = aisleepFindDevices;
/**
 * Registers an Aisleep device with the Aisleep server.
 *
 * @param db
 * @param center the care center in which the device is being registered.
 * @param serialNumber the serial number of the device being registered.
 *
 * @return registration status
 */
const aisleepRegisterDevice = (session, deviceId, facilityId, settings) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        // check for duplicate
        const duplicate = yield (0, exports.aisleepFindDeviceInfo)(session, deviceId);
        if (duplicate !== undefined) {
            throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.CONFLICT, errors_1.ACErrorCodes.ErrorAisleepSensorAlreadyRegistered, 'Device ' +
                deviceId +
                ' is already registered in AiSleep facility with id: ' +
                duplicate.homeid, undefined, { url: session.url, deviceId, facilityId: duplicate.homeid });
        }
        const facility = yield (0, exports.aisleepFindFacility)(session, facilityId);
        if (!facility) {
            throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.BAD_REQUEST, errors_1.ACErrorCodes.ErrorAisleepFacilityNotFound, 'Facility (' + facilityId + ') not found in AiSleep', undefined, { url: session.url, deviceId, facilityId });
        }
        const infoRequest = {
            registerDeviceInfo: aisleepInitRegisterDeviceInfo(deviceId, facility.id, settings),
        };
        let status = yield session.api.device.registerDeviceInfo(infoRequest);
        logger.debug('registerDeviceInfo', { deviceId, status });
        if (status.success !== 'true') {
            throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.INTERNAL_SERVER_ERROR, errors_1.ACErrorCodes.ErrorAisleep, 'Failed to register device in AiSleep', undefined, { url: session.url, deviceId, facilityId, status });
        }
        const _settings = (0, exports.mapACApiDeviceSettingsToAiSleepApi)(settings);
        status = yield session.api.device.changeDeviceSettings({
            deviceSettingsWithId: Object.assign({ deviceid: deviceId }, _settings),
        });
        logger.debug('changeDeviceSettings', { deviceId, status });
        if (status.success !== 'true') {
            throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.INTERNAL_SERVER_ERROR, errors_1.ACErrorCodes.ErrorAisleep, 'Device settings could not be changed in AiSleep', undefined, { url: session.url, deviceId, facilityId, status });
        }
        const device = yield (0, exports.aisleepFindDevice)(session, deviceId);
        const info = yield (0, exports.aisleepFindDeviceInfo)(session, deviceId);
        if (device && info) {
            const acDevice = (0, exports.mapAisleepDeviceToAC)(device, info);
            return acDevice;
        }
        else {
            throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.INTERNAL_SERVER_ERROR, errors_1.ACErrorCodes.ErrorAisleep, 'Device info was registered in AC but device was not subsequently found: ' + deviceId, undefined, { url: session.url, deviceId, facilityId });
        }
    }
    catch (e) {
        throw yield errorMessage(e, errors_1.ACErrorCodes.ErrorAisleep, 'unable to register device');
    }
});
exports.aisleepRegisterDevice = aisleepRegisterDevice;
/**
 * Update an Aisleep device's settings in the Aisleep server.
 *
 * @param session the Aisleep session
 * @param deviceId the serial number of the device being updated.
 * @param settings the settings
 * @param newFacilityId the facility where the device should be located.
 *   If undefined, then the device remains in it's current facility.
 * @param newDeviceId the new id that should be used for the device.
 *   If undefined, then the device id remains unchanged.
 *
 * @return update status
 */
const aisleepUpdateDevice = (session, deviceId, settings, newFacilityId, newDeviceId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const deviceInfo = yield (0, exports.aisleepFindDeviceInfo)(session, deviceId);
        if (!deviceInfo) {
            throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.BAD_REQUEST, errors_1.ACErrorCodes.ErrorAisleepSensorNotFound, 'Sensor (' + deviceId + ') not found in AiSleep', undefined, { url: session.url, deviceId, facilityId: newFacilityId });
        }
        if ((newFacilityId !== undefined && deviceInfo.homeid !== newFacilityId) ||
            (newDeviceId !== undefined && newDeviceId !== deviceInfo.deviceid)) {
            if (newFacilityId && newFacilityId !== deviceInfo.homeid) {
                // verify the facility exists
                const facility = yield (0, exports.aisleepFindFacility)(session, newFacilityId);
                if (!facility) {
                    throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.BAD_REQUEST, errors_1.ACErrorCodes.ErrorAisleepFacilityNotFound, 'Facility (' + newFacilityId + ') not found in AiSleep', undefined, { url: session.url, deviceId, facilityId: newFacilityId });
                }
            }
            const infoStatus = yield session.api.device.updateDeviceInfo({
                deviceInfoWithId: aisleepUpdateDeviceInfo(deviceInfo, newFacilityId ? newFacilityId : deviceInfo.homeid, newDeviceId ? newDeviceId : deviceInfo.deviceid, settings),
            });
            if (infoStatus.success !== 'true') {
                throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.INTERNAL_SERVER_ERROR, errors_1.ACErrorCodes.ErrorAisleep, 'Sensor (' + deviceId + ') could not be updated in AiSleep', undefined, {
                    url: session.url,
                    deviceId,
                    newDeviceId,
                    facilityId: newFacilityId ? newFacilityId : deviceInfo.homeid,
                    status: infoStatus,
                    settings,
                });
            }
        }
        const _settings = (0, exports.mapACApiDeviceSettingsToAiSleepApi)(settings);
        const status = yield session.api.device.changeDeviceSettings({
            deviceSettingsWithId: Object.assign({ deviceid: deviceId }, _settings),
        });
        logger.debug('changeDeviceSettings', { deviceId, status });
        if (status.success !== 'true') {
            throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.INTERNAL_SERVER_ERROR, errors_1.ACErrorCodes.ErrorAisleep, 'Device settings could not be changed in AiSleep', undefined, {
                url: session.url,
                deviceId,
                facilityId: newFacilityId,
                status,
                settings,
                aisleepSettings: _settings,
            });
        }
        const device = yield (0, exports.aisleepFindDevice)(session, deviceId);
        const info = yield (0, exports.aisleepFindDeviceInfo)(session, deviceId);
        if (device && info) {
            const acDevice = (0, exports.mapAisleepDeviceToAC)(device, info);
            return acDevice;
        }
        else {
            throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.INTERNAL_SERVER_ERROR, errors_1.ACErrorCodes.ErrorAisleep, 'Device info was registered in AC but device was not subsequently found: ' + deviceId, undefined, { url: session.url, deviceId, facilityId: newFacilityId });
        }
    }
    catch (e) {
        throw yield errorMessage(e, errors_1.ACErrorCodes.ErrorAisleep, 'unable to update device');
    }
});
exports.aisleepUpdateDevice = aisleepUpdateDevice;
/**
 * Registers an Aisleep device with the Aisleep server.
 *
 * @param db
 * @param center the care center in which the device is being registered.
 * @param serialNumber the serial number of the device being registered.
 *
 * @return registration status
 */
const aisleepDeleteDevice = (session, deviceId) => __awaiter(void 0, void 0, void 0, function* () {
    const info = yield (0, exports.aisleepFindDeviceInfo)(session, deviceId);
    if (!info) {
        throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.NOT_FOUND, errors_1.ACErrorCodes.ErrorAisleepSensorNotFound, 'Device not found on AiSleep server: ' + deviceId, undefined, { url: session.url, deviceId });
    }
    else {
        const status = yield session.api.device.deleteDevice({ id: info.id.toString() });
        if (status.success !== 'true') {
            throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.INTERNAL_SERVER_ERROR, errors_1.ACErrorCodes.ErrorAisleep, 'Device could not be deleted on AiSleep server: ' + deviceId, undefined, { url: session.url, deviceId, status });
        }
    }
});
exports.aisleepDeleteDevice = aisleepDeleteDevice;
/**
 * Defaults and min/max values for user configuration settings in the Aisleep cloud
 */
const aisleepDefaultAlarmSettings = () => {
    return {
        // heart
        hrAlerts: true,
        hrMax: 110,
        hrMin: 40,
        hrOver: 60,
        // respiratory
        rrAlerts: true,
        rrMax: 25,
        rrMin: 8,
        rrOver: 60,
        // moving (pressure ulcer)
        movAlerts: false,
        movMin: 10,
        movOver: 300,
        // bed exit
        bedExitAlerts: true,
        bedExitTime: 0,
        // sitting
        sittingAlerts: true,
        situpTime: 0,
        // shallow breathing
        shallowRespAlerts: false,
        shallowRespMin: 5,
        shallowRespOver: 8, // duration in seconds
    };
};
exports.aisleepDefaultAlarmSettings = aisleepDefaultAlarmSettings;
const defaultAisleepAlertSettings = () => {
    const aisleepConfig = (0, exports.aisleepDefaultAlarmSettings)();
    return {
        processFallAlerts: aisleepConfig.bedExitAlerts !== undefined ? aisleepConfig.bedExitAlerts : true,
        processHeartAlerts: aisleepConfig.hrAlerts !== undefined ? aisleepConfig.hrAlerts : true,
        processRespirationAlerts: aisleepConfig.rrAlerts !== undefined ? aisleepConfig.rrAlerts : true,
        processRespiratoryDepression: aisleepConfig.shallowRespAlerts !== undefined ? aisleepConfig.shallowRespAlerts : false,
        processMovementAlerts: aisleepConfig.movAlerts !== undefined ? aisleepConfig.movAlerts : false,
        processSittingAlerts: aisleepConfig.sittingAlerts !== undefined ? aisleepConfig.sittingAlerts : true,
        fallTimeRange: TimeRange_1.DEFAULT_TIMERANGE_ALWAYS,
        // Config settings stored in Aisleep cloud
        aisleepConfig,
    };
};
exports.defaultAisleepAlertSettings = defaultAisleepAlertSettings;
/**
 * gets all sensor settings, both those managed by AC and those managed in vendor systems.
 *
 * @param db
 * @param resident
 */
const getAisleepUserConfigRiskLevel = (type, aisleepSettings) => {
    let level = models_1.RiskLevelType.NONE;
    switch (type) {
        case models_1.AlertType.FALL:
        case models_1.AlertType.SITTING:
            if (aisleepSettings.processFallAlerts) {
                level = models_1.RiskLevelType.MEDIUM;
            }
            break;
        case models_1.AlertType.HEART:
            if (aisleepSettings.processHeartAlerts) {
                level = models_1.RiskLevelType.MEDIUM;
            }
            break;
        case models_1.AlertType.BREATHING:
            if (aisleepSettings.processRespirationAlerts ||
                aisleepSettings.processRespiratoryDepression) {
                level = models_1.RiskLevelType.MEDIUM;
            }
            break;
        default:
            level = models_1.RiskLevelType.NONE;
    }
    return level;
};
exports.getAisleepUserConfigRiskLevel = getAisleepUserConfigRiskLevel;
/**
 * Gets any daily logs the are available on the specified time period.
 * A date is passed in. The daily logs for that and the previous number of
 * days are retrieved.
 *
 * @param db
 * @param resident The resident whose sleep session is beingn looked up
 * @param date The date of the sleep session.
 * @param days The previous number of days of sleep sessions to include in the results.
 *
 * @return The sleep sessions.
 */
// export const getDailyLogs = async (
//   db: Database,
//   resident: Resident,
//   date: Date,
//   days = 1
// ): Promise<types.aisleepClient.DailyLogData[]> => {
//   const dates: Date[] = [];
//   for (let d = date, i = 0; i < days; i++) {
//     dates.push(d);
//     d = moment(d).subtract(1, 'day').toDate();
//   }
//   logger.debug('getSleepSession:', { dates });
//   const sessions = await Promise.all(dates.map((d) => getDailyLogsOnDate(db, resident, d)));
//   const all: types.aisleepClient.DailyLogData[] = [];
//   sessions.forEach((s) => all.push(...s));
//   return all.sort((a, b) => a.dayReportYmd.localeCompare(b.dayReportYmd));
// };
/**
 * Retrieves a resident's daily logs on a specific date.
 *
 * @param db
 * @param resident The resident
 * @param date The date of the daily logs
 * @returns The daily logs
 */
// export const getDailyLogsOnDate = (
//   db: Database,
//   resident: Resident,
//   date: Date
// ): Promise<Array<types.aisleepClient.DailyLogData>> =>
//   db
//     .api()
//     .then((api) =>
//       api.sleep.getAisleepDailyLogs({
//         residentId: resident.snapshot.id,
//         t: date.toISOString(),
//       })
//     )
//     .catch(async (e) => {
//       const err = await errorMessage(
//         e,
//         ACErrorCodes.ErrorInternal,
//         'unable to query AiSleep daily logs'
//       );
//       throw err;
//     });
// /**
//  * Gets most recent daily log start times.
//  *
//  * @param token API JWT token to use in request
//  * @param resident The resident whose sleep session is beingn looked up
//  * @param limit Limit the number of responses.
//  *
//  * @return The daily log start times.
//  */
// export const getDailyLogStartTimes = (db: Database, resident: Resident, limit?: number) =>
//   db
//     .api()
//     .then((api) =>
//       api.sleep.getAisleepDailyLogStartTimes({
//         residentId: resident.snapshot.id,
//         limit,
//       })
//     )
//     .catch(async (e) => {
//       const err = await errorMessage(
//         e,
//         ACErrorCodes.ErrorInternal,
//         'unable to query aisleep daily log start times'
//       );
//       throw err;
//     });
/**
 * Gets aisleep vitals for a time period.
 *
 * @param db
 * @param resident The resident whose vitals are being queried
 * @param startTime The start time for the vitals
 * @param hrs The number of hours of vitals.
 *
 * @return The AiSleep vitals for the patient
 */
const getVitals = (api, resident, startTime, hrs) => api.aisleep
    .getResidentAisleepVitals({
    residentId: resident.snapshot.id,
    t: startTime.toISOString(),
    hrs,
})
    .catch((e) => __awaiter(void 0, void 0, void 0, function* () {
    const err = yield errorMessage(e, errors_1.ACErrorCodes.ErrorInternal, 'unable to query aisleep vitals');
    throw err;
}));
exports.getVitals = getVitals;
const aisleepResolveDeviceAlarm = (session, deviceId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        // throw exception if device doesn't exist
        yield (0, exports.aisleepFindDevice)(session, deviceId);
        const alarmStop = {
            name: session.username,
            skey: session.skey,
            deviceid: deviceId,
            type: aisleep.AlarmStopTypeEnum.NUMBER_1,
        };
        const status = yield session.api.device.stopAlarm({ alarmStop });
        if (status.success !== 'true') {
            throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.INTERNAL_SERVER_ERROR, errors_1.ACErrorCodes.ErrorAisleep, 'Device (' + deviceId + ') alarm could not be stopped.', undefined, { url: session.url, deviceId, status, alarmStop });
        }
    }
    catch (e) {
        throw yield errorMessage(e, errors_1.ACErrorCodes.ErrorAisleepAlarm, 'unable to resolve alarms in AiSleep cloud');
    }
});
exports.aisleepResolveDeviceAlarm = aisleepResolveDeviceAlarm;
const aisleepDisableDeviceAlarms = (session, deviceId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        // throw exception if device doesn't exist
        yield (0, exports.aisleepFindDevice)(session, deviceId);
        const alarmStop = {
            name: session.username,
            skey: session.skey,
            deviceid: deviceId,
            type: aisleep.AlarmStopTypeEnum.NUMBER_2,
        };
        const status = yield session.api.device.stopAlarm({ alarmStop });
        if (status.success !== 'true') {
            throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.INTERNAL_SERVER_ERROR, errors_1.ACErrorCodes.ErrorAisleep, 'Device (' + deviceId + ') alarms could not be disabled.', undefined, { url: session.url, deviceId, status, alarmStop });
        }
    }
    catch (e) {
        throw yield errorMessage(e, errors_1.ACErrorCodes.ErrorAisleepAlarm, 'unable to resolve alarms in AiSleep cloud');
    }
});
exports.aisleepDisableDeviceAlarms = aisleepDisableDeviceAlarms;
const aisleepEnableDeviceAlarms = (session, deviceId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        // throw exception if device doesn't exist
        yield (0, exports.aisleepFindDevice)(session, deviceId);
        const alarmStop = {
            name: session.username,
            skey: session.skey,
            deviceid: deviceId,
            type: aisleep.AlarmStopTypeEnum.NUMBER_3,
        };
        const status = yield session.api.device.stopAlarm({ alarmStop });
        if (status.success !== 'true') {
            throw new ErrorMessage_1.default(http_status_codes_1.StatusCodes.INTERNAL_SERVER_ERROR, errors_1.ACErrorCodes.ErrorAisleep, 'Device (' + deviceId + ') alarms could not be enabled.', undefined, { url: session.url, deviceId, status, alarmStop });
        }
    }
    catch (e) {
        throw yield errorMessage(e, errors_1.ACErrorCodes.ErrorAisleepAlarm, 'unable to enable alarms in AiSleep cloud');
    }
});
exports.aisleepEnableDeviceAlarms = aisleepEnableDeviceAlarms;
/**
 * Maps a device from AiSleep to AC API.
 *
 * @param device
 * @returns
 */
const mapAisleepDeviceToAC = (device, info) => {
    const result = {
        deviceId: device.did,
        facilityId: info.homeid,
        settings: (0, exports.mapAisleepDeviceSettingsToAC)(device, info),
    };
    return result;
};
exports.mapAisleepDeviceToAC = mapAisleepDeviceToAC;
/**
 * Maps device settings from AiSleep to AC API.
 *
 * @param device
 * @param info
 * @returns
 */
const mapAisleepDeviceSettingsToAC = (device, info) => {
    const settings = {
        // Info
        userName: info.username,
        roomName: info.roomname ? info.roomname : undefined,
        address: info.address ? info.address : undefined,
        airMattress: info.haseamato !== undefined ? Boolean(info.haseamato) : undefined,
        // Alert settings
        hrAlerts: Boolean(device.hrflg),
        hrMax: device.hrmax,
        hrMin: device.hrmin,
        hrOver: device.hrover,
        rrAlerts: Boolean(device.reflg),
        rrMax: device.remax,
        rrMin: device.remin,
        rrOver: device.reover,
        movAlerts: Boolean(device.movflg),
        movMin: device.movmin,
        movOver: device.movover,
        bedExitAlerts: Boolean(device.outbedflg),
        bedExitTime: device.outbedtime,
        sittingAlerts: Boolean(device.situpflg),
        situpTime: device.situptime,
        shallowRespAlerts: Boolean(device.rewflg),
        shallowRespMin: device.rewmin,
        shallowRespOver: device.rewover,
    };
    return settings;
};
exports.mapAisleepDeviceSettingsToAC = mapAisleepDeviceSettingsToAC;
/**
 * Maps sensor settings from AC API to AiSleep API
 *
 * @param device
 * @returns
 */
const mapACApiDeviceSettingsToAiSleepApi = (device) => {
    const settings = {
        hrflg: device.hrAlerts ? 1 : 0,
        hrmax: device.hrMax,
        hrmin: device.hrMin,
        hrover: device.hrOver,
        reflg: device.rrAlerts ? 1 : 0,
        remax: device.rrMax,
        remin: device.rrMin,
        reover: device.rrOver,
        movflg: device.movAlerts ? 1 : 0,
        movmin: device.movMin,
        movover: device.movOver,
        outbedflg: device.bedExitAlerts ? 1 : 0,
        outbedtime: device.bedExitTime,
        situpflg: device.sittingAlerts ? 1 : 0,
        situptime: device.situpTime,
        rewflg: device.shallowRespAlerts ? 1 : 0,
        rewmin: device.shallowRespMin,
        rewover: device.shallowRespOver,
    };
    return settings;
};
exports.mapACApiDeviceSettingsToAiSleepApi = mapACApiDeviceSettingsToAiSleepApi;
/**
 * Maps sensor settings from AC API to AiSleep API
 *
 * @param device
 * @returns
 */
const mapSensorAlertSettingsToACApiAisleepDeviceSettings = (settings) => {
    const aisleep = settings ? settings : (0, exports.defaultAisleepAlertSettings)();
    const mapped = Object.assign({ 
        // Info
        userName: undefined, roomName: undefined, address: undefined, airMattress: undefined }, aisleep.aisleepConfig);
    if (logger.isDebugEnabled()) {
        logger.debug('mapSensorAlertSettingsToACApiAisleepDeviceSettings', {
            settings,
            mapped,
        });
    }
    return mapped;
};
exports.mapSensorAlertSettingsToACApiAisleepDeviceSettings = mapSensorAlertSettingsToACApiAisleepDeviceSettings;
const aisleepInitRegisterDeviceInfo = (deviceId, homeId, settings) => {
    return {
        address: settings.address ? settings.address : '-',
        deviceid: deviceId,
        homeid: homeId.toString(),
        haseamato: settings.airMattress !== undefined ? (settings.airMattress ? 1 : 0) : 0,
        hasmov: settings.movAlerts ? 1 : 0,
        roomname: settings.roomName ? settings.roomName : 'AC',
        // display the device id in the AiSleep UI
        username: settings.userName ? settings.userName : deviceId,
    };
};
const aisleepUpdateDeviceInfo = (info, homeId, deviceId, settings) => {
    // info.updateTime = new Date().toISOString();
    info.username = settings.userName ? settings.userName : info.deviceid;
    info.address = settings.address ? settings.address : info.address;
    info.haseamato = settings.airMattress !== undefined ? (settings.airMattress ? 1 : 0) : 1;
    info.hasmov = settings.movAlerts ? 1 : 0;
    info.homeid = homeId;
    info.deviceid = deviceId;
    return info;
};
/**
 * Updates alert settings for a resident at the Aisleep server.
 *
 * @param db the AC database
 * @param resident the resident who's settings are being changed
 */
const aisleepUpdateAlertSettings = (db, resident, settings) => __awaiter(void 0, void 0, void 0, function* () {
    if (logger.isDebugEnabled()) {
        logger.debug('updateAlertSettings()');
        logger.debug({ resident: resident.data, settings });
    }
    if (!db.user) {
        throw new errors_1.ACError('AC DB connection is not authenticated', errors_1.ACErrorCodes.ErrorNotAuthenticated);
    }
    const token = yield db.user.getIdToken();
    const center = yield db.careCenters.findByRef(resident.data.careCenterRef);
    if (!center) {
        throw new errors_1.ACError('Resident assigned to patient does not exist.', errors_1.ACErrorCodes.ErrorInvalidCareCenterId);
    }
    if (!center.data.aisleep) {
        throw new errors_1.ACError('AiSleep server is not configured for this care center.', errors_1.ACErrorCodes.ErrorMissingConfiguration, { param: 'center.data.aisleep' });
    }
    if (!center.data.aisleep.useProxy) {
        if (!center.data.aisleep.server ||
            !center.data.aisleep.server.url ||
            !center.data.aisleep.server.username ||
            !center.data.aisleep.server.password ||
            !center.data.aisleep.facilityId) {
            throw new errors_1.ACError('AiSleep server is not configured for this care center.', errors_1.ACErrorCodes.ErrorMissingConfiguration, { param: 'center.data.aisleep' });
        }
        const sensors = (yield db.sensors.getResidentSensors(resident)).filter((s) => s.data.sensorType === models_1.SensorType.AISLEEP);
        try {
            const session = yield types.acApi.loadSession((0, settings_1.servicesURL)(), token, db.user.uid);
            const aisleepUrl = center.data.aisleep.server.url;
            const aisleepToken = yield session.aisleep.aisleepLogin({
                url: aisleepUrl,
                u: center.data.aisleep.server.username,
                p: center.data.aisleep.server.password,
            });
            yield Promise.all(sensors.map((s) => {
                var _a, _b;
                if (((_a = s.data.aisleep) === null || _a === void 0 ? void 0 : _a.serialNumber) && ((_b = center.data.aisleep) === null || _b === void 0 ? void 0 : _b.facilityId)) {
                    const req = {
                        url: aisleepUrl,
                        token: aisleepToken.token,
                        deviceId: s.data.aisleep.serialNumber,
                        aisleepDeviceSettingsRequest: {
                            settings: (0, exports.mapSensorAlertSettingsToACApiAisleepDeviceSettings)(settings),
                        },
                    };
                    if (logger.isDebugEnabled()) {
                        logger.debug('updateAlertSettings() calling API');
                        console.log(req);
                    }
                    return session.aisleep.aisleepUpdateSensor(req);
                }
            }));
        }
        catch (e) {
            const error = yield errorMessage(e, errors_1.ACErrorCodes.ErrorAisleep, 'Sensor could not be registered in the AiSleep cloud.');
            logger.error('Sensor alert settings could not be updated in the AiSleep cloud', error);
            throw error;
        }
    }
    const sensorSettings = yield db.sensorAlertSettings.getByResident(resident);
    sensorSettings.data.aisleep = settings;
    return db.sensorAlertSettings.update(sensorSettings);
});
exports.aisleepUpdateAlertSettings = aisleepUpdateAlertSettings;
/**
 * Registers an AiSleep sensor in the AC database and in the AiSleep cloud.
 *
 * @param db connection the the AC database
 * @param serialNumber the serial number of the sensor
 * @param timeZone the time zone where the sensor is located
 * @param previousSerialNumber if the serial number is being changed, this contains the original number.
 * It is used to lookup the existing sensor.
 */
const aisleepRegisterSensor = (db, center, serialNumber, timeZone, previousSerialNumber) => __awaiter(void 0, void 0, void 0, function* () {
    if (logger.isDebugEnabled()) {
        logger.info('registerSensor()', {
            center: center.snapshot.id,
            serialNumber,
            timeZone,
            previousSerialNumber,
        });
    }
    if (!center.data.aisleep ||
        (!center.data.aisleep.useProxy &&
            (!center.data.aisleep.server ||
                !center.data.aisleep.server.url ||
                !center.data.aisleep.server.username ||
                !center.data.aisleep.server.password ||
                !center.data.aisleep.facilityId))) {
        throw new errors_1.ACError('AiSleep server is not configured for this care center.', errors_1.ACErrorCodes.ErrorMissingConfiguration, { param: 'center.data.aisleep' });
    }
    if (!db.user) {
        throw new errors_1.ACError('Not authenticated', errors_1.ACErrorCodes.ErrorNotAuthenticated);
    }
    const token = yield db.user.getIdToken();
    const serialNumberChanged = previousSerialNumber ? previousSerialNumber !== serialNumber : false;
    if (serialNumberChanged) {
        const sensors = yield db.sensors.getAisleepSensorBySerialNumber(serialNumber);
        if (sensors.length > 0) {
            // serial number is already in use - prevent duplicates
            throw new errors_1.ACError('Aisleep serial number ' + serialNumber + ' is already registered.', errors_1.ACErrorCodes.ErrorSensorRegisterDuplicateId, { id: serialNumber });
        }
    }
    const sensors = yield db.sensors.getAisleepSensorBySerialNumber(previousSerialNumber ? previousSerialNumber : serialNumber);
    const sensor = {
        id: sensors.length > 0 ? sensors[0].id : undefined,
        snapshot: sensors.length > 0 ? sensors[0].snapshot : undefined,
        data: {
            sensorType: models_1.SensorType.AISLEEP,
            name: serialNumber,
            careCenterRef: center.snapshot.ref.path,
            residentRef: sensors.length > 0 ? sensors[0].data.residentRef : null,
            ccjBedRef: null,
            biobeat: null,
            sensingWave: null,
            aisleep: {
                serialNumber,
                timeZone,
            },
            disabled: false,
            info: sensors.length > 0 ? sensors[0].data.info : null,
        },
    };
    // validate that we can make changes
    if (sensors.length > 0 && sensors[0].data.careCenterRef !== center.snapshot.ref.path) {
        throw new errors_1.ACError('Aisleep sensor ' +
            serialNumber +
            ' is already registered at another care center: ' +
            sensor.data.careCenterRef, errors_1.ACErrorCodes.ErrorSensorRegisterAlreadyRegisteredAtOtherCareCenter, { id: serialNumber, center: center.data.name });
    }
    // save in AiSleep cloud if we aren't using the proxy server
    if (!center.data.aisleep.useProxy &&
        center.data.aisleep.server &&
        center.data.aisleep.facilityId) {
        try {
            const session = yield types.acApi.loadSession((0, settings_1.servicesURL)(), token, db.user.uid);
            const aisleepUrl = center.data.aisleep.server.url;
            const aisleepToken = yield session.aisleep.aisleepLogin({
                url: aisleepUrl,
                u: center.data.aisleep.server.username,
                p: center.data.aisleep.server.password,
            });
            let aisleepSensor = yield session.aisleep
                .aisleepGetSensor({
                url: aisleepUrl,
                token: aisleepToken.token,
                deviceId: serialNumber,
            })
                .catch(() => undefined);
            if (!aisleepSensor && previousSerialNumber) {
                aisleepSensor = yield session.aisleep
                    .aisleepGetSensor({
                    url: aisleepUrl,
                    token: aisleepToken.token,
                    deviceId: previousSerialNumber,
                })
                    .catch(() => undefined);
            }
            if (aisleepSensor) {
                // update existing sensor in aisleep cloud...
                logger.debug('update existing sensor: ' + aisleepSensor.deviceId);
                // easier to register a new sensor and delete the previous one...
                if (serialNumberChanged && previousSerialNumber) {
                    aisleepSensor = yield session.aisleep.aisleepRegisterSensor({
                        url: aisleepUrl,
                        token: aisleepToken.token,
                        aisleepRegisterDeviceRequest: {
                            deviceId: serialNumber,
                            facilityId: center.data.aisleep.facilityId,
                            settings: (0, exports.aisleepDefaultAlarmSettings)(),
                        },
                    });
                    // now delete the previous one
                    yield session.aisleep.aisleepDeleteSensor({
                        url: aisleepUrl,
                        token: aisleepToken.token,
                        deviceId: previousSerialNumber,
                    });
                }
                else {
                    yield session.aisleep.aisleepUpdateSensor({
                        url: aisleepUrl,
                        token: aisleepToken.token,
                        deviceId: aisleepSensor.deviceId,
                        aisleepDeviceSettingsRequest: {
                            deviceId: serialNumber,
                            facilityId: center.data.aisleep.facilityId,
                            settings: aisleepSensor.settings,
                        },
                    });
                }
            }
            else {
                // register new sensor in aisleep cloud
                logger.debug('registering new sensor: ' + serialNumber);
                yield session.aisleep.aisleepRegisterSensor({
                    url: aisleepUrl,
                    token: aisleepToken.token,
                    aisleepRegisterDeviceRequest: {
                        deviceId: serialNumber,
                        facilityId: center.data.aisleep.facilityId,
                        settings: (0, exports.aisleepDefaultAlarmSettings)(),
                    },
                });
            }
        }
        catch (e) {
            const error = yield errorMessage(e, errors_1.ACErrorCodes.ErrorAisleep, 'Sensor could not be registered in the AiSleep cloud.');
            logger.error('Sensor could not be registered in the AiSleep cloud', error);
            throw error;
        }
    }
    // save the current change in AC database
    if (sensor.snapshot) {
        return yield db.sensors
            .updateSensor({
            data: sensor.data,
            id: sensor.snapshot.id,
            snapshot: sensor.snapshot,
        })
            .catch((e) => (0, exports.aisleepDetachSensor)(db, sensor.data).then(() => {
            throw e;
        }));
    }
    else {
        return yield db.sensors.addSensor(sensor.data).catch((e) => (0, exports.aisleepDetachSensor)(db, sensor.data).then(() => {
            throw e;
        }));
    }
});
exports.aisleepRegisterSensor = aisleepRegisterSensor;
/**
 * Detach a sensor from a user.
 *
 * @param db
 * @param sensor
 * @returns
 */
const aisleepDetachSensor = (db, sensor) => {
    // This is a NOOP right now - in future we may add capabilities...
    if (db.user) {
        return Promise.resolve(sensor);
    }
    else {
        throw new errors_1.ACError('AC DB connection is not authenticated', errors_1.ACErrorCodes.ErrorNotAuthenticated);
    }
};
exports.aisleepDetachSensor = aisleepDetachSensor;
/**
 * Assigns a sensor to a resident.
 *
 * @param db the AC database
 * @param sensor the sensor to be assigned to the resident
 * @param resident the resident who will be using the sensor
 * @see https://docs.google.com/drawings/d/1I_dBinLGNGAlYO4WESdWBRFfbbVZLDNM5jbpwGR6MSY/edit?usp=sharing
 */
const aisleepAssignSensorToResident = (db, sensor, resident) => __awaiter(void 0, void 0, void 0, function* () {
    if (logger.isDebugEnabled()) {
        logger.debug('assignSensorToResident()', {
            sensor: sensor.data,
            resident: resident ? resident.data : null,
        });
    }
    if (!db.user) {
        throw new errors_1.ACError('AC DB connection is not authenticated', errors_1.ACErrorCodes.ErrorNotAuthenticated);
    }
    if (sensor.data.sensorType !== models_1.SensorType.AISLEEP) {
        throw new errors_1.ACError('Invalid sensor type. Can only assign AISLEEP sensors.', errors_1.ACErrorCodes.ErrorInternal);
    }
    if (resident) {
        if (sensor.data.careCenterRef !== resident.data.careCenterRef) {
            throw new errors_1.ACError('Sensor and resident care centers must be the same in order to assign a sensor to a resident.', errors_1.ACErrorCodes.ErrorSensorAssignCareCenterMismatch, { id: sensor.data.name });
        }
        if (sensor.data.residentRef !== resident.snapshot.ref.path) {
            sensor.data.residentRef = resident.snapshot.ref.path;
            yield db.sensors.updateSensor(sensor);
            const settings = yield db.sensorAlertSettings.getByResident(resident);
            yield (0, exports.aisleepUpdateAlertSettings)(db, resident, settings.data.aisleep ? settings.data.aisleep : (0, exports.defaultAisleepAlertSettings)());
        }
    }
    else {
        // unassign
        sensor.data.residentRef = null;
        yield db.sensors.updateSensor(sensor);
    }
    // we also clear any vitals that were associated with the sensor
    const vitals = yield db.sensorVitals.findBySensorRef(sensor.snapshot.ref.path);
    yield Promise.all(vitals.map((v) => {
        if (v.data.aisleep) {
            v.data.aisleep = null;
            return db.sensorVitals.update(v).then(() => Promise.resolve());
        }
        return Promise.resolve();
    })).then(() => Promise.resolve());
});
exports.aisleepAssignSensorToResident = aisleepAssignSensorToResident;
/**
 * Removes an AiSleep sensor from both the AiSleep cloud and from AC.
 *
 * @param db
 * @param center
 * @param serialNumber the sensor to remove
 */
const aisleepRemoveSensor = (db, center, serialNumber) => __awaiter(void 0, void 0, void 0, function* () {
    if (logger.isDebugEnabled()) {
        logger.info('removeSensor()', {
            center: center.snapshot.id,
            serialNumber,
        });
    }
    if (!db.user) {
        throw new errors_1.ACError('Not authenticated', errors_1.ACErrorCodes.ErrorNotAuthenticated);
    }
    const token = yield db.user.getIdToken();
    // remove from AiSleep cloud
    if (center.data.aisleep &&
        center.data.aisleep.server &&
        center.data.aisleep.server.url &&
        center.data.aisleep.server.username &&
        center.data.aisleep.server.password &&
        center.data.aisleep.facilityId &&
        !center.data.aisleep.useProxy) {
        try {
            const session = yield types.acApi.loadSession((0, settings_1.servicesURL)(), token, db.user.uid);
            const aisleepUrl = center.data.aisleep.server.url;
            const aisleepToken = yield session.aisleep.aisleepLogin({
                url: aisleepUrl,
                u: center.data.aisleep.server.username,
                p: center.data.aisleep.server.password,
            });
            const aisleepSensor = yield session.aisleep
                .aisleepGetSensor({
                url: aisleepUrl,
                token: aisleepToken.token,
                deviceId: serialNumber,
            })
                .catch(() => undefined);
            if (aisleepSensor) {
                // delete existing sensor in aisleep cloud...
                logger.debug('update existing sensor: ' + aisleepSensor.deviceId);
                yield session.aisleep.aisleepDeleteSensor({
                    url: aisleepUrl,
                    token: aisleepToken.token,
                    deviceId: aisleepSensor.deviceId,
                });
            }
        }
        catch (e) {
            const error = yield errorMessage(e, errors_1.ACErrorCodes.ErrorAisleep, 'Sensor could not be deleted in the AiSleep cloud.');
            logger.error('Sensor could not be deleted in the AiSleep cloud', error);
            throw error;
        }
    }
    // sensor was deleted from aisleep cloud, now delete from AC
    const sensors = yield db.sensors.getAisleepSensorBySerialNumber(serialNumber);
    yield Promise.all(sensors.map((s) => db.sensors.deleteSensor(s)));
});
exports.aisleepRemoveSensor = aisleepRemoveSensor;
