"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sensingWaveDetachSensor = exports.sensingWaveRegisterSensor = exports.getSensingWaveUserConfigRiskLevel = exports.defaultSensingWaveAlertSettings = void 0;
const errors_1 = require("../errors");
const logger_1 = require("../logger");
const models_1 = require("../models");
const TimeRange_1 = require("../models/TimeRange");
const logger = (0, logger_1.getLogger)('/services/SensingWaveService');
const defaultSensingWaveAlertSettings = () => {
    return {
        processFallAlerts: true,
        processHeartAlerts: true,
        processRespirationAlerts: true,
        processMovementAlerts: false,
        processSittingAlerts: true,
        processPositionAlerts: false,
        processRoomTemperatureAlerts: false,
        processRoomHumidityAlerts: false,
        // Time ranges when alerts should be processed
        fallTimeRange: TimeRange_1.DEFAULT_TIMERANGE_ALWAYS,
    };
};
exports.defaultSensingWaveAlertSettings = defaultSensingWaveAlertSettings;
/**
 * Get risk level for a given alert type.
 *
 * @param type - Alert type
 * @param sensingWaveSettings - SensingWave alert settings
 */
const getSensingWaveUserConfigRiskLevel = (type, sensingWaveSettings) => {
    let level = models_1.RiskLevelType.NONE;
    switch (type) {
        case models_1.AlertType.FALL:
        case models_1.AlertType.SITTING:
            if (sensingWaveSettings.processFallAlerts) {
                level = models_1.RiskLevelType.MEDIUM;
            }
            break;
        case models_1.AlertType.HEART:
            if (sensingWaveSettings.processHeartAlerts) {
                level = models_1.RiskLevelType.MEDIUM;
            }
            break;
        case models_1.AlertType.BREATHING:
            if (sensingWaveSettings.processRespirationAlerts) {
                level = models_1.RiskLevelType.MEDIUM;
            }
            break;
        default:
            level = models_1.RiskLevelType.NONE;
    }
    return level;
};
exports.getSensingWaveUserConfigRiskLevel = getSensingWaveUserConfigRiskLevel;
/**
 * Registers a SensingWave sensor in the AC database.
 *
 * @param db connection the the AC database
 * @param serialNumber the serial number of the sensor
 * @param timeZone the time zone where the sensor is located
 * @param previousSerialNumber if the serial number is being changed, this contains the original number.
 * It is used to lookup the existing sensor.
 */
const sensingWaveRegisterSensor = (db, center, serialNumber, timeZone, previousSerialNumber) => __awaiter(void 0, void 0, void 0, function* () {
    if (logger.isDebugEnabled()) {
        logger.info('registerSensor()', {
            center: center.snapshot.id,
            serialNumber,
            timeZone,
            previousSerialNumber,
        });
    }
    if (!db.user) {
        throw new errors_1.ACError('Not authenticated', errors_1.ACErrorCodes.ErrorNotAuthenticated);
    }
    const token = yield db.user.getIdToken();
    const serialNumberChanged = previousSerialNumber ? previousSerialNumber !== serialNumber : false;
    if (serialNumberChanged) {
        const sensors = yield db.sensors.getSensingWaveSensorBySerialNumber(serialNumber);
        if (sensors.length > 0) {
            // serial number is already in use - prevent duplicates
            throw new errors_1.ACError('SensingWaven serial number ' + serialNumber + ' is already registered.', errors_1.ACErrorCodes.ErrorSensorRegisterDuplicateId, { id: serialNumber });
        }
    }
    const sensors = yield db.sensors.getSensingWaveSensorBySerialNumber(previousSerialNumber ? previousSerialNumber : serialNumber);
    const sensor = {
        id: sensors.length > 0 ? sensors[0].id : undefined,
        snapshot: sensors.length > 0 ? sensors[0].snapshot : undefined,
        data: {
            sensorType: models_1.SensorType.SENSING_WAVE,
            name: serialNumber,
            careCenterRef: center.snapshot.ref.path,
            residentRef: sensors.length > 0 ? sensors[0].data.residentRef : null,
            ccjBedRef: null,
            biobeat: null,
            aisleep: null,
            sensingWave: {
                serialNumber,
                timeZone,
            },
            disabled: false,
            info: sensors.length > 0 ? sensors[0].data.info : null,
        },
    };
    // validate that we can make changes
    if (sensors.length > 0 && sensors[0].data.careCenterRef !== center.snapshot.ref.path) {
        throw new errors_1.ACError('Sensingwave sensor ' +
            serialNumber +
            ' is already registered at another care center: ' +
            sensor.data.careCenterRef, errors_1.ACErrorCodes.ErrorSensorRegisterAlreadyRegisteredAtOtherCareCenter, { id: serialNumber, center: center.data.name });
    }
    // save the current change in AC database
    if (sensor.snapshot) {
        return yield db.sensors
            .updateSensor({
            data: sensor.data,
            id: sensor.snapshot.id,
            snapshot: sensor.snapshot,
        })
            .catch((e) => (0, exports.sensingWaveDetachSensor)(db, sensor.data).then(() => {
            throw e;
        }));
    }
    else {
        return yield db.sensors.addSensor(sensor.data).catch((e) => (0, exports.sensingWaveDetachSensor)(db, sensor.data).then(() => {
            throw e;
        }));
    }
});
exports.sensingWaveRegisterSensor = sensingWaveRegisterSensor;
/**
 * Detach a sensor from a user.
 *
 * @param db
 * @param sensor
 * @returns
 */
const sensingWaveDetachSensor = (db, sensor) => {
    // This is a NOOP right now - in future we may add capabilities...
    if (db.user) {
        return Promise.resolve(sensor);
    }
    else {
        throw new errors_1.ACError('AC DB connection is not authenticated', errors_1.ACErrorCodes.ErrorNotAuthenticated);
    }
};
exports.sensingWaveDetachSensor = sensingWaveDetachSensor;
