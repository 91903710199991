import { Store as Database } from 'attentive-connect-store/dist';
import * as models from 'attentive-connect-store/dist/models';
import CareCenter from 'attentive-connect-store/dist/models/CareCenter';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Hybrid } from '../hybrid';
import { getLogger } from '../logger';
import * as redux from '../redux';

const logger = getLogger('services/AlertNotificationService', 'info');

interface Props {
  // dispatch props
  setOpenAlerts: typeof redux.context.setOpenAlerts;
  clearOpenAlerts: typeof redux.context.clearOpenAlerts;

  // state (redux) props
  db?: Database;
  center?: CareCenter | null;
  listenAlerts: boolean;
}

type AllProps = Props;

class AlertNotificationService extends React.Component<AllProps> {
  private openAlertsCount = 0;

  get nameSpace() {
    return 'ui.services.AlertNotificationService.alerts';
  }

  async componentDidMount() {
    logger.debug('componentDidMount()', {
      db: !!this.props.db,
      center: !!this.props.center,
      listenAlerts: this.props.listenAlerts,
    });
    await this.stop();
    await this.start();
  }

  async componentDidUpdate(prevProps: AllProps) {
    if (
      this.props.db !== prevProps.db ||
      this.props.center?.id !== prevProps.center?.id ||
      this.props.listenAlerts !== prevProps.listenAlerts
    ) {
      logger.debug('componentDidUpdate()', {
        db: !!this.props.db,
        center: !!this.props.center,
        listenAlerts: this.props.listenAlerts,
      });
      await this.stop();
      await this.start();
    }
  }

  async componentWillUnmount() {
    // logger.trace("will unmount");
    logger.debug('componentWillUnmount()', {
      db: !!this.props.db,
      center: !!this.props.center,
      listenAlerts: this.props.listenAlerts,
    });
    await this.stop();
  }

  debugListeners = () => {
    if (this.props.db) {
      const listeners = this.props.db.listeners
        .getAllListeners()
        .filter((l) => l.nameSpace === this.nameSpace);
      listeners.forEach((l) => {
        if (l.nameSpace === this.nameSpace) {
          logger.debug('listener', {
            namespace: l.nameSpace,
            id: l.id,
          });
        }
      });
    }
  };

  start = async () => {
    const { db, center, listenAlerts } = this.props;

    if (!center || !db || !listenAlerts) {
      logger.debug('start() - did not start listening', {
        db: !!db,
        center: !!center,
        listenAlerts,
      });

      return;
    }

    logger.debug('start()', { center: center.snapshot.id });
    this.debugListeners();

    db.listeners.stopAllInNamespace(this.nameSpace);

    const listener = await db.alerts
      .listenAlertStats(this.nameSpace, center, (stats) => {
        logger.debug('alert stats changed', {
          center: center.snapshot.id,
          openAlerts: stats.openAlerts,
        });
        if (stats.openAlerts > 0) {
          logger.debug('update open alerts');
          this.updateAlertsInRedux();
          if (this.openAlertsCount !== stats.openAlerts) {
            // vibrate if alert count changes
            const hybrid = Hybrid.instance();
            if (hybrid && hybrid.vibrationPlugin) {
              hybrid.vibrationPlugin.vibrate(1000);
            }
            this.openAlertsCount = stats.openAlerts;
          }
        } else {
          logger.debug('clear open alerts');
          this.props.clearOpenAlerts();
          const hybrid = Hybrid.instance();
          if (hybrid && hybrid.vibrationPlugin) {
            hybrid.vibrationPlugin.vibrate(0);
          }
        }
      })
      .catch((e) => {
        logger.error('listenAlertStats failed', e);
        return undefined;
      });

    logger.debug('start() - listener started', {
      namespace: listener?.nameSpace,
      center: center.snapshot.id,
    });
    this.debugListeners();
  };

  stop = async () => {
    const { db } = this.props;
    logger.debug('stop()');
    if (db) {
      db.listeners.stopAllInNamespace(this.nameSpace);
    }
    this.debugListeners();
  };

  clearOpenAlerts = () => {
    logger.debug('clearing open alerts');
    this.props.clearOpenAlerts();
  };

  updateAlertsInRedux = async () => {
    const { db, center } = this.props;
    if (db && center) {
      logger.debug('update alerts in redux');
      const alerts = await db.alerts.getOpenAlerts(center.snapshot.ref.path);
      logger.debug('update alerts in redux [get open alerts]');
      const alertsWithSensorDetail = await Promise.all(
        alerts.map((a) => db.alerts.getAlertWithSensorDetail(a))
      );
      if (alerts.length > 0) {
        logger.debug('[redux] update alert count', { count: alertsWithSensorDetail.length });
        this.props.setOpenAlerts(alertsWithSensorDetail);
      } else {
        logger.debug('[redux] clear open alerts');
        this.props.clearOpenAlerts();
      }
    }
  };

  render() {
    return <></>;
  }
}

const mapStateToProps = (application: redux.ApplicationState) => ({
  db: application.auth.database,
  center: application.context.center,
  listenAlerts: application.context.listenAlerts,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setOpenAlerts: (alerts: models.AlertWithSensorDetail[]) =>
    dispatch(redux.context.setOpenAlerts(alerts)),
  clearOpenAlerts: () => dispatch(redux.context.clearOpenAlerts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertNotificationService);
