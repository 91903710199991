import { Theme } from '@mui/material';
import { Store as Database } from 'attentive-connect-store/dist';
import * as models from 'attentive-connect-store/dist/models';
import * as types from 'attentive-connect-store/dist/types';
import { StringsIntl } from '../languages';
import { getLogger } from '../logger';
import * as Aisleep from './Aisleep';
import * as Alerts from './Alerts';
import * as Residents from './Residents';
import * as SensingWave from './SensingWave';
import {
  InBedStatusType,
  SittingStatusType,
  SleepStatusType,
  aisleepInBedStatus,
  aisleepSleepStatus,
  getAisleepVitals,
  getBioBeatVitals,
  getBleVitals,
  getSensingWaveVitals,
  sensingWaveInBedStatus,
  sensingWaveSleepStatus,
} from './Sensors';
import * as Vitals from './Vitals';
import deepEqual from 'deep-equal';

const logger = getLogger('data/Dashboard', 'info');

export interface TileData {
  db: Database;
  center: models.CareCenter;
  resident: models.Resident;
  bioBeatVitals?: Vitals.BioBeatVitals;
  aisleepVitals?: Vitals.AisleepVitals;
  sensingWaveVitals?: Vitals.SensingWaveVitals;
  alerts: Alerts.AlertViewData[];
  sensors: models.Sensor[];
  sensorsStatus: models.SensorStatus[];
  sensorsInfo: string[];
  cameras: models.Camera[];
  // camerasVisibility: boolean[];
  roomTemp: number | undefined;
  bleVitals?: types.acApi.Vitals[];
  contextMenuIsOpen: boolean;
  bedOccupancy: models.BedOccupancy[];
}

export const getTileData = (
  theme: Theme,
  db: Database,
  center: models.CareCenter | undefined,
  residents: models.Resident[],
  sensors: models.Sensor[],
  cameras: models.Camera[],
  bioBeatVitals: Vitals.BioBeatVitals[],
  aisleepVitals: Vitals.AisleepVitals[],
  sensingWaveVitals: Vitals.SensingWaveVitals[],
  bleVitals: Record<string, types.acApi.Vitals[]> | undefined,
  alerts: models.AlertWithSensorDetail[],
  bedOccupancy: models.BedOccupancy[],
  localized: StringsIntl,
  contextMenuIsOpen = false
): TileData[] => {
  const tiles: TileData[] = [];
  if (center) {
    residents.forEach((r) => {
      const residentSensors = filterResidentSensors(r, sensors);
      const residentCameras = cameras.filter((c) => c.data.residentRef === r.snapshot.ref.path);
      // if (bleVitals) {
      //   console.log(getBleVitals(r, bleVitals, localized));
      // }
      if (residentSensors.length || residentCameras.length) {
        const _alerts = sortAlerts(
          getAlerts(
            r,
            Alerts.toAlertViewDataListWithSensorDetail(theme, alerts, sensors, residents, [], [])
          ).filter((a) => !a.isOneClickHidden()),
          localized
        );

        tiles.push({
          db,
          center,
          resident: r,
          // bioBeat vitals to be displayed in dashboard.
          bioBeatVitals: getBioBeatVitals(r, sensors, bioBeatVitals, localized),
          aisleepVitals: getAisleepVitals(r, sensors, aisleepVitals, localized),
          sensingWaveVitals: getSensingWaveVitals(r, sensors, sensingWaveVitals, localized),
          bleVitals: bleVitals ? getBleVitals(r, bleVitals, localized) : undefined,
          // N.B. we aren't passing users in because only dealing with open alerts
          // users is only used for resolved alerts
          alerts: _alerts,
          sensors: residentSensors.sort((a, b) =>
            a.snapshot.ref.path.localeCompare(b.snapshot.ref.path)
          ),
          cameras: residentCameras,
          // camerasVisibility: residentCameras.map(() => showCamera),
          roomTemp: undefined,
          sensorsStatus: [],
          sensorsInfo: [],
          contextMenuIsOpen,
          bedOccupancy,
        });
      }
      tiles.forEach((t) => {
        t.sensorsStatus = sensorStatus(db, t, bioBeatVitals, aisleepVitals, sensingWaveVitals);
        t.sensorsInfo = sensorInfo(t, localized);
      });
    });
  }
  return sortTilesByPriority(tiles, bedOccupancy, localized);
};

const filterResidentSensors = (resident: models.Resident, sensors: models.Sensor[]) =>
  sensors.filter((s) => s.data.residentRef === resident.snapshot.ref.path);

const sensorStatus = (
  db: Database,
  tile: TileData,
  bioBeatVitals: Vitals.BioBeatVitals[],
  aisleepVitals: Vitals.AisleepVitals[],
  sensingWaveVitals: Vitals.SensingWaveVitals[]
): models.SensorStatus[] => {
  return tile.sensors.map((s) => {
    if (db.sensors.isBioBeatSensor(s.data)) {
      logger.debug('BIOBEAT');
      const vitals: types.biobeat.Vitals[] = [];
      const bbVitals = bioBeatVitals.find((v) => {
        if (s.data.biobeat) {
          return v.sensorId === s.data.biobeat.Patch_ID;
        }
        return false;
      });
      bioBeatVitals.forEach((v) => v.vitals && vitals.push(v.vitals));

      const status = db.sensors.getBioBeatVitalsStatus(bbVitals ? bbVitals.vitals : undefined);

      // logger.debug("BIOBEAT status", { status, sensor: s.data, vitals });

      // RETURN
      return status;
    } else if (db.sensors.isAisleepSensor(s.data)) {
      const x = aisleepVitals.find((y) => s.snapshot.id === y.sensor.snapshot.id);
      const status = db.sensors.getTimedAisleepVitalsStatus(
        tile.center,
        x ? x.sensor.data.aisleep : null,
        x ? x.vitals.data.aisleep : null
      );

      // RETURN
      return status;
    } else if (db.sensors.isSensingWaveSensor(s.data)) {
      const x = sensingWaveVitals.find((y) => s.snapshot.id === y.sensor.snapshot.id);
      const status = db.sensors.getTimedSensingWaveVitalsStatus(
        tile.center,
        x ? x.sensor.data.sensingWave : null,
        x ? x.vitals.data.sensingWave : null
      );

      // RETURN
      return status;
    } else {
      logger.notice('Sensor is not recognized.', { sensor: s.data });
      // this should not happen
      return 'other';
    }
  });
};

const sensorInfo = (tile: TileData, localized: StringsIntl): string[] => {
  return tile.sensors.map((s) => {
    let info = '';
    switch (s.data.sensorType) {
      case models.SensorType.BIOBEAT:
        info = localized.biobeat.biobeat();
        if (s.data.biobeat) {
          info += ' (' + s.data.biobeat.Patch_ID + ')';
        }
        break;
      case models.SensorType.AISLEEP:
        info = localized.aisleep.aisleep();
        if (s.data.aisleep) {
          info += ' (' + s.data.aisleep.serialNumber + ')';
        }
        break;
      case models.SensorType.SENSING_WAVE:
        info = localized.sensingWave.sensingWave();
        if (s.data.sensingWave) {
          info += ' (' + s.data.sensingWave.serialNumber + ')';
        }
        break;
    }
    return info;
  });
};

export const sortTilesByPriority = (
  tiles: TileData[],
  bedOccupancy: models.BedOccupancy[],
  localized: StringsIntl
) => {
  return tiles.sort((a, b) => {
    if (a.alerts.length > b.alerts.length) {
      return -1;
    }
    if (a.alerts.length < b.alerts.length) {
      return 1;
    }
    if (a.alerts.length > 0) {
      const c = Alerts.alertCompare(a.alerts[0], b.alerts[0], localized);
      if (c !== 0) {
        return c;
      }
    }
    return inBedStatusCompare(a, b, bedOccupancy, localized);
    // return Residents.roomCompare(a.resident, b.resident, localized);
  });
};

export const sortTilesByRoom = (tiles: TileData[], localized: StringsIntl) => {
  return tiles.sort((a, b) => {
    let compare = Residents.highPriorityDispCompare(a.resident, b.resident);

    if (compare === 0) {
      compare = Residents.roomCompare(a.resident, b.resident, localized);
    }
    if (compare === 0) {
      compare = Residents.residentCompare(a.resident, b.resident, localized);
    }
    if (compare === 0) {
      compare = Residents.bedCompare(a.resident, b.resident, localized);
    }
    if (compare === 0) {
      compare = Residents.floorCompare(a.resident, b.resident, localized);
    }

    return compare;
  });
};

export const sortTilesByInBedStatus = (
  tiles: TileData[],
  bedOccupancy: models.BedOccupancy[],
  localized: StringsIntl
) => {
  return tiles.sort((a, b) => {
    return inBedStatusCompare(a, b, bedOccupancy, localized);
  });
};

export const inBedStatusCompare = (
  a: TileData,
  b: TileData,
  bedOccupancy: models.BedOccupancy[],
  localized: StringsIntl
) => {
  const aStatus = inBedStatus(a, bedOccupancy);
  const bStatus = inBedStatus(b, bedOccupancy);

  if (aStatus !== bStatus) {
    if (aStatus === 'in') {
      return 1;
    }
    if (bStatus === 'in') {
      return -1;
    }
  }
  return Residents.roomCompare(a.resident, b.resident, localized);
};

export const movementLevel = (tile: TileData): number | undefined => {
  if (tile.aisleepVitals) {
    return Aisleep.aisleepMovementLevel(tile.db, tile.aisleepVitals);
  } else if (tile.sensingWaveVitals) {
    return SensingWave.movementLevel(tile.db, tile.sensingWaveVitals);
  } else {
    return undefined;
  }
};

export const sleepStatus = (
  tile: TileData,
  bedOccupancy: models.BedOccupancy[]
): SleepStatusType => {
  if (tile.aisleepVitals) {
    return aisleepSleepStatus(
      tile.db,
      tile.center,
      tile.resident,
      tile.sensors,
      tile.aisleepVitals,
      bedOccupancy
    );
  } else if (tile.sensingWaveVitals) {
    return sensingWaveSleepStatus(
      tile.db,
      tile.center,
      tile.resident,
      tile.sensors,
      tile.sensingWaveVitals,
      bedOccupancy
    );
  } else {
    return 'na';
  }
};

export const sittingStatus = (tile: TileData): SittingStatusType => {
  if (tile.aisleepVitals) {
    return aisleepSittingStatus(
      tile.db,
      tile.center,
      tile.resident,
      tile.sensors,
      tile.aisleepVitals
    );
  } else if (tile.sensingWaveVitals) {
    return sensingWaveSittingStatus(
      tile.db,
      tile.center,
      tile.resident,
      tile.sensors,
      tile.sensingWaveVitals
    );
  } else {
    return 'na';
  }
};

export const isStatusUnknown = (tile: TileData): boolean | undefined => {
  if (tile.aisleepVitals) {
    return Aisleep.aisleepIsStatusUnknown(tile.db, tile.aisleepVitals);
  } else if (tile.sensingWaveVitals) {
    return SensingWave.isStatusUnknown(tile.db, tile.sensingWaveVitals);
  } else {
    return undefined;
  }
};

export const aisleepSittingStatus = (
  db: Database,
  center: models.CareCenter,
  resident: models.Resident,
  sensors: models.Sensor[],
  vitals: Vitals.AisleepVitals | undefined
): SittingStatusType => {
  let status: SittingStatusType = 'na';

  if (vitals && vitals.vitals) {
    status = Aisleep.aisleepSittingStatus(db, vitals);
  }

  return status;
};

export const sensingWaveSittingStatus = (
  db: Database,
  center: models.CareCenter,
  resident: models.Resident,
  sensors: models.Sensor[],
  vitals: Vitals.SensingWaveVitals | undefined
): SittingStatusType => {
  let status: SittingStatusType = 'na';

  if (vitals && vitals.vitals) {
    status = SensingWave.sittingStatus(db, vitals);
  }

  return status;
};

export const inBedStatus = (
  tile: TileData,
  bedOccupancy: models.BedOccupancy[]
): InBedStatusType => {
  if (tile.aisleepVitals) {
    logger.debug('inBedStatus: checking AISLEEEP');
    return aisleepInBedStatus(
      tile.db,
      tile.center,
      tile.resident,
      tile.sensors,
      tile.aisleepVitals,
      bedOccupancy
    );
  } else if (tile.sensingWaveVitals) {
    logger.debug('inBedStatus: checking SENSING_WAVE');
    return sensingWaveInBedStatus(
      tile.db,
      tile.center,
      tile.resident,
      tile.sensors,
      tile.sensingWaveVitals,
      bedOccupancy
    );
  } else {
    logger.debug('inBedStatus: NO vitals to check');
    return 'na';
  }
};

export const tilesWithInBedStatsStatus = (
  tileData: TileData[],
  bedOccupancy: models.BedOccupancy[],
  status: InBedStatusType
) => {
  const tiles = tileData.filter((t) => inBedStatus(t, bedOccupancy) === status);
  return tiles;
};

export const tilesWithoutInBedStatus = (
  tileData: TileData[],
  bedOccupancy: models.BedOccupancy[],
  status: InBedStatusType
) => {
  const tiles = tileData.filter((t) => inBedStatus(t, bedOccupancy) !== status);
  return tiles;
};

export const hasAlerts = (tile: TileData) =>
  tile.alerts.filter((a) => !a.isOneClickHidden()).length > 0;

export const hasWarnings = (tile: TileData, bedOccupancy: models.BedOccupancy[]) =>
  tile.resident.data.warningsDisabled !== true &&
  (inBedStatus(tile, bedOccupancy) === 'out-warning' ||
    sleepStatus(tile, bedOccupancy) === 'awake-warning');

export const hasAwakes = (tile: TileData, bedOccupancy: models.BedOccupancy[]) =>
  sleepStatus(tile, bedOccupancy) === 'awake' ||
  inBedStatus(tile, bedOccupancy) === 'out-warning' ||
  sleepStatus(tile, bedOccupancy) === 'awake-warning';

export const hasActiveSensors = (tile: TileData) =>
  (tile.bioBeatVitals && tile.bioBeatVitals.sensorIsActive) ||
  (tile.aisleepVitals && tile.aisleepVitals.sensorIsActive) ||
  (tile.sensingWaveVitals && tile.sensingWaveVitals.sensorIsActive);

/**
 * Returns true if the tile has sensors but none are active.
 */
export const hasNoActiveSensors = (tile: TileData): boolean => {
  if (tile.sensors.length > 0) {
    return !hasActiveSensors(tile);
  }
  return false;
};

/**
 * returns all tiles that have alerts.
 * @param tileData
 */
export const tilesWithAlerts = (tileData: TileData[]) => tileData.filter((t) => hasAlerts(t));

/**
 * returns all tiles that have in bed status of "out-warning"
 * or sleep status "awake-warning"
 * @param tileData
 */
export const tilesWithWarnings = (tileData: TileData[], bedOccupancy: models.BedOccupancy[]) =>
  tileData.filter((t) => hasWarnings(t, bedOccupancy));

/**
 * returns all tiles that dont' have in bed status of "out-warning"
 * and don't have sleep status "awake-warning"
 * @param tileData
 */
export const tilesWithoutWarnings = (tileData: TileData[], bedOccupancy: models.BedOccupancy[]) =>
  tileData.filter((t) => !hasWarnings(t, bedOccupancy));

/**
 * returns all tiles with "Awake"
 * @param tileData
 */
export const tilesWithAwakes = (tileData: TileData[], bedOccupancy: models.BedOccupancy[]) =>
  tileData.filter((t) => hasAwakes(t, bedOccupancy));

/**
 * returns all tiles without alerts and without in bed stats "Awake"
 * @param tileData
 */
export const tilesWithoutAwakes = (tileData: TileData[], bedOccupancy: models.BedOccupancy[]) =>
  tileData.filter((t) => !hasAwakes(t, bedOccupancy));

/**
 * returns all tiles without alerts and without in bed stats "out"
 * @param tileData
 */
export const tilesWithoutAlerts = (tileData: TileData[]) => tileData.filter((t) => !hasAlerts(t));

/**
 * returns all tiles witout active sensors
 * @param tileData
 */
export const tilesWithoutActiveSensors = (tileData: TileData[]) =>
  tileData.filter((t) => hasNoActiveSensors(t));

/**
 * returns all tiles with active sensors
 * @param tileData
 */
export const tilesWithActiveSensors = (tileData: TileData[]) =>
  tileData.filter((t) => hasActiveSensors(t));

/**
 * getAlerts returns the alerts related to the resident.
 */
export const getAlerts = (resident: models.Resident, alerts: Alerts.AlertViewData[]) => {
  return alerts.filter(
    (a) => a.resident && a.resident.snapshot.id === resident.snapshot.id && a.isOpen()
  );
};

/**
 * sortAlerts sorts alerts in the order that they should be presented in the dashboard.
 */
export const sortAlerts = (alerts: Alerts.AlertViewData[], localized: StringsIntl) => {
  return alerts.sort((x, y) => Alerts.alertCompare(x, y, localized));
};

export const getAlertPauseRemaining = (tile: TileData) => {
  const remaining = tile.db.residents.disabledAlertsTimeRemaining(tile.resident);

  if (remaining === undefined) {
    return -1;
  } else {
    return remaining;
  }
};

export const getAlertPauseRemainingPercent = (tile: TileData) => {
  const remaining = getAlertPauseRemaining(tile);
  if (remaining >= 0) {
    return Math.ceil((remaining / Residents.DEFAULT_ALERTS_PAUSED_DURATION) * 100);
  }
  return -1;
};

export const hasStatus = (tile: TileData) => {
  if (
    inBedStatus(tile, tile.bedOccupancy) !== 'na' ||
    (tile.bioBeatVitals && tile.bioBeatVitals.vitals) ||
    (tile.aisleepVitals && tile.aisleepVitals.vitals) ||
    (tile.sensingWaveVitals && tile.sensingWaveVitals.vitals)
  ) {
    return true;
  }
  return false;
};

export const tilesDiffer = (
  listA: TileData[],
  listB: TileData[],
  localized: StringsIntl,
  checkAlerts = true,
  checkVitals = true,
  checkBle = true
) => {
  logger.debug('tilesDiffer()', { listA: listA.length, listB: listB.length });
  logger.debug('tilesDiffer()', {
    listA: listA.map((t) => ({ resident: t.resident.id })),
    listB: listB.map((t) => ({ resident: t.resident.id })),
  });

  let diff = listA.length !== listB.length;
  if (!diff) {
    const sortedA = sortTilesByRoom(listA, localized);
    const sortedB = sortTilesByRoom(listB, localized);

    logger.debug('tilesDiffer()', {
      sortedA: sortedA.map((t) => ({ resident: t.resident.id })),
      sortedB: sortedB.map((t) => ({ resident: t.resident.id })),
    });

    sortedA.some((a, idx) => {
      const b: TileData = sortedB[idx];
      logger.debug('tilesDiffer()', {
        diff,
        a: { resident: a.resident.id },
        b: { resident: b.resident.id },
      });
      if (!diff) {
        diff = !deepEqual(a.resident.data, b.resident.data);
      }
      if (!diff) {
        diff = a.contextMenuIsOpen !== b.contextMenuIsOpen;
      }
      if (!diff) {
        diff = a.cameras.length !== b.cameras.length;
        if (diff && logger.isDebugEnabled()) {
          logger.debug('tileDataIsDifferent() cameras length changed');
        }
      }
      if (!diff) {
        const aSorted = a.cameras.sort((x, y) => x.data.cameraId.localeCompare(y.data.cameraId));
        const bSorted = b.cameras.sort((x, y) => x.data.cameraId.localeCompare(y.data.cameraId));
        aSorted.some((a1, idx1) => {
          if (idx1 < bSorted.length) {
            const b1: models.Camera = bSorted[idx1];
            diff = a1.id !== b1.id;
            return diff;
          } else {
            return true;
          }
        });
      }
      if (!diff) {
        diff = a.resident.snapshot.id !== b.resident.snapshot.id;
        if (diff && logger.isDebugEnabled()) {
          logger.debug('tile resident changed');
        }
      }
      if (!diff) {
        diff = a.sensorsStatus.length !== b.sensorsStatus.length;
        if (diff && logger.isDebugEnabled()) {
          logger.debug('tile sensor status length changed');
        }
      }
      if (!diff) {
        // statuses are alerady sorted. if we sort here first need to make a copy...
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        diff = !deepEqual(a.sensorsStatus, b.sensorsStatus);
        if (diff && logger.isDebugEnabled()) {
          logger.debug('tile sensor status changed', {
            before: a.sensorsStatus,
            after: b.sensorsStatus,
          });
        }
      }
      if (!diff && checkAlerts) {
        diff = a.alerts.length !== b.alerts.length;
        if (diff && logger.isDebugEnabled()) {
          logger.debug('tile alerts length changed');
        }
      }
      if (!diff && checkAlerts) {
        a.alerts.some((a1, idx1) => {
          const b1: Alerts.AlertViewData = b.alerts[idx1];
          diff = a1.alertWithSensorDetail.alert.id !== b1.alertWithSensorDetail.alert.id;
          if (!diff) {
            // check if the sensor alert changed
            if (
              a1.alertWithSensorDetail.sensorAlert?.data &&
              b1.alertWithSensorDetail.sensorAlert?.data
            ) {
              diff = !deepEqual(
                a1.alertWithSensorDetail.sensorAlert.data,
                b1.alertWithSensorDetail.sensorAlert.data
              );
            }
          }
          return diff;
        });
        if (diff && logger.isDebugEnabled()) {
          logger.debug('tile alerts changed');
        }
      }
      // aisleep
      if (!diff) {
        diff =
          (a.aisleepVitals !== undefined && b.aisleepVitals === undefined) ||
          (a.aisleepVitals === undefined && b.aisleepVitals !== undefined);
        if (diff && logger.isDebugEnabled()) {
          logger.debug('tile aisleep vitals changed 1');
        }
      }
      if (!diff && a.aisleepVitals !== undefined && b.aisleepVitals !== undefined) {
        const aStatus = a.aisleepVitals.sensorStatus();
        const bStatus = b.aisleepVitals.sensorStatus();

        diff =
          aStatus !== bStatus ||
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          !deepEqual(a.aisleepVitals.vitals.data.aisleep, b.aisleepVitals.vitals.data.aisleep);

        if (diff && logger.isDebugEnabled()) {
          logger.debug('tile aisleep vitals changed 2');
        }
      }
      // sensingWave
      if (!diff) {
        diff =
          (a.sensingWaveVitals !== undefined && b.sensingWaveVitals === undefined) ||
          (a.sensingWaveVitals === undefined && b.sensingWaveVitals !== undefined);
        if (diff && logger.isDebugEnabled()) {
          logger.debug('tile sensingWave vitals changed 1');
        }
      }
      if (!diff && a.sensingWaveVitals !== undefined && b.sensingWaveVitals !== undefined) {
        const aStatus = a.sensingWaveVitals.sensorStatus();
        const bStatus = b.sensingWaveVitals.sensorStatus();

        diff =
          aStatus !== bStatus ||
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          !deepEqual(
            a.sensingWaveVitals.vitals.data.sensingWave,
            b.sensingWaveVitals.vitals.data.sensingWave
          );

        if (diff && logger.isDebugEnabled()) {
          logger.debug('tile sensingWave vitals changed 2');
        }
      }

      if (checkVitals) {
        if (!diff && a.bioBeatVitals !== undefined && b.bioBeatVitals !== undefined) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          diff = !deepEqual(a.bioBeatVitals, b.bioBeatVitals);
          if (diff && logger.isDebugEnabled()) {
            logger.debug('tile biobeat vitals changed');
          }
        }
      }
      if (checkBle) {
        // was undefined, becomes defined.  case not handled for other vitals, may be unique to ble
        if (!diff && a.bleVitals === undefined && b.bleVitals !== undefined) {
          diff = true;
          if (diff && logger.isDebugEnabled()) {
            logger.debug('tile ble vitals changed (1)');
          }
        }
        if (!diff && a.bleVitals !== undefined && b.bleVitals !== undefined) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          diff = !deepEqual(a.bleVitals, b.bleVitals);
          if (diff && logger.isDebugEnabled()) {
            logger.debug('tile ble vitals changed (2)');
          }
        }
      }
      return diff;
    });
  }
  return diff;
};
